import React from "react";
import * as ModalContainer from "react-modal";
import Modals from "../Modals";
import "./styles.scss";

const Modal = ({ ...props }) => {
  let customStyles = {
    overlay: {
      zIndex: 1000,
      overflow: "auto",
      backgroundColor: "rgba(50, 50, 50, 0.7)",
    },
    content: {
      flex: 1,
      top: props.top || "50px",
      left: "50%",
      bottom: "auto",
      height: "auto",
      width: props.width || "70%",
      marginRight: "-50%",
      transform: "translate(-50%, 0px)",
      border: "none",
      backgroundColor: "#F0F0F0",
      boxShadow: "0px 2px 3px 0px rgba(50, 50, 50, 0.3)",
      padding: "0px",
      marginBottom: "20px",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
      borderRadius: props.borderRadius || "5px",
      zIndex: "10000",
    },
  };

  const renderModal = (type, props) => {
    switch (type) {
      case "createAppointment":
        return <Modals.CreateAppointment props={props} />;
      case "appoitmentApproved":
        return <Modals.SuccessModal props={props} />;
      case "videoCall":
        return <Modals.SuccessModal props={props} />;
      case "appoitmentDeclined":
        return <Modals.SuccessModal props={props} />;
      case "userDecline":
        return <Modals.SuccessModal props={props} />;
      case "createAppointmentSuccess":
        return <Modals.SuccessModal props={props} />;
      case "success":
        return <Modals.SuccessModal props={props} />;
      case "error":
        return <Modals.ErrorModal props={props} />;
      case "errorPass":
        return <Modals.PassErrorModal props={props} />;
      case "errorPassDontMatch":
        return <Modals.PassErrorModal props={props} />;
      case "cancelAppointment":
        return <Modals.CancelModal props={props} />;
      default:
        return null;
    }
  };

  return (
    <ModalContainer
      id={props.id}
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      contentLabel={`${props.title ? props.title : "Missing title"}`}
      style={customStyles}
      shouldCloseOnOverlayClick
      appElement={document.getElementById("root")}
    >
      <div
        className={[
          "modal-container ",
          props.classNames ? props.classNames.join(" ") : "",
        ]}
      >
        {renderModal(props.type, props)}
      </div>
    </ModalContainer>
  );
};

export default Modal;
