import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../utils/theme";
import { Notification } from "react-admin";

const MyLayout = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <Notification />
  </ThemeProvider>
);

export default MyLayout;
