import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import { Notification } from "react-admin";
import { api } from "../../../api";
import { theme } from "../../../utils/theme";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.common.headerColor,
    color: theme.palette.primary.contrastText,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.secondary.light,
  },
  card: {
    minWidth: 300,
    marginTop: "7em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.common.headerColor,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primaryJsColor2,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  loginBtn: {
    backgroundColor: theme.palette.common.commonBtnBgColor,
    "&:hover": {
      backgroundColor: theme.palette.common.commonBtnHoverBgColor,
    },
  },
}));
const ForgottenPass = () => {
  const [email, setEmail] = useState();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar variant="dense">
          <Avatar className={classes.icon}>
            <EventAvailableIcon />
          </Avatar>
          <Typography
            variant="body1"
            component="h1"
            align="center"
            style={{ width: "100%" }}
          >
            {process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Записване на часове за подаване на заявления в СГКК София – град"
              : "Портал за заявяване на срещи с администрацията"}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}></div>
          <div className={classes.form}>
            <div className={classes.input}>
              <TextField
                fullWidth
                label="Електронна поща"
                value={email}
                onChange={(x) => setEmail(x.target.value)}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              className={classes.loginBtn}
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              onClick={() =>
                api.getForgotPassword(email).then((response) => {
                  switch (response.status) {
                    case 200:
                      setNotification({
                        open: true,
                        message:
                          "Успешно подадена заявка за забравена парола, очаквайте емайл",
                      });
                      setTimeout(
                        () => setNotification({ open: false, message: "" }),
                        5000
                      );
                      break;
                    case 409:
                      setNotification({
                        open: true,
                        message: "Емейлът който сте въвели не е регистриран",
                      });
                      setTimeout(
                        () => setNotification({ open: false, message: "" }),
                        5000
                      );
                      break;

                    default:
                      setNotification({
                        open: true,
                        message: "Възникна системна грешка",
                      });
                      setTimeout(
                        () => setNotification({ open: false, message: "" }),
                        5000
                      );
                      break;
                  }
                })
              }
            >
              Възстанови
            </Button>
          </CardActions>
          <Notification {...notification} />
          <div className="enter-login-container">
            <Link className={classes.toLoginLink} to={{ pathname: "/login" }}>
              <span className={classes.toLoginBtn}>Към вход</span>
            </Link>
          </div>
        </Card>
      </div>
    </>
  );
};

ForgottenPass.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const ForgottenPassPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <ForgottenPass {...props} />
  </ThemeProvider>
);

export default ForgottenPassPage;
