import React from 'react'
import './styles.scss'

const NavButton = ({ ...props }) => {
  return (
    <div className={props.styles ? `${props.styles} nav-btn-container` : 'nav-btn-container'}>
      <div
        className={props.linkStyle ? `${props.linkStyle} nav-btn` : 'nav-btn'}
        href={props.href ? props.href : ''}
        pressed={props.pressed ? props.pressed : null}
        onClick={props.onClick}
      >
        {props.text || 'text'}
      </div>
      {props.children}
    </div>
  )
}

export default NavButton
