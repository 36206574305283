import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  toggleUserModal,
  toggleSuccessModal,
  toggleVideoCall,
} from "../../actions/user";
import { makeGetUser } from "../../selectors/selectors";
import "./styles.scss";

const UserUpcommingTable = (props) => {
  return (
    <table className="main-history-table">
      <thead>
        <tr>
          <th style={{ flex: 1 }} className="data-type">
            Дата
          </th>
          <th style={{ flex: 1 }} className="data-type">
            Номер
          </th>
          <th style={{ flex: 3 }} className="data-type">
            Тема
          </th>
          <th style={{ flex: 1 }} className="data-type">
            Час
          </th>
          <th style={{ flex: 2 }} className="data-type">
            {process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Часът"
              : "Срещата"}{" "}
            ще се проведе с
          </th>
          {!["cadastre", "pernik"].includes(process.env.REACT_APP_INSTANCE) && (
            <th style={{ flex: 1 }} className="data-type">
              Онлайн
            </th>
          )}
          <th style={{ flex: 1 }} className="data-type" />
        </tr>
      </thead>
      <tbody>
        {props.user.appointments &&
          props.user.appointments.future_meetings.map((appoint, i) => {
            return (
              <tr key={i}>
                <td style={{ flex: 1 }} className="hour">
                  {moment(appoint.start_hour).format("DD.MM.YYYY")}
                </td>
                <td style={{ flex: 1 }} className="hour">
                  {appoint.id}
                </td>
                <td style={{ flex: 3 }} className="hour">
                  {appoint?.topic}
                </td>
                <td style={{ flex: 1 }} className="hour">
                  {moment(appoint.start_hour).format("HH:mm")}
                </td>
                <td style={{ flex: 2 }} className="hour">
                  {appoint.department_name}
                </td>
                {!["cadastre", "pernik"].includes(
                  process.env.REACT_APP_INSTANCE
                ) && (
                  <td style={{ flex: 1 }} className="hour">
                    {appoint.meeting_url && (
                      <a href={appoint.meeting_url}>
                        <img
                          className="online"
                          alt="online"
                          src={require("../../assets/images/camera.svg")}
                        />
                      </a>
                    )}
                  </td>
                )}
                <td
                  style={{ flex: 1 }}
                  className="hour color-primaryColor1"
                  onClick={() => {
                    props.toggleSuccess();
                    if (props.declineMeeting) {
                      props.declineMeeting(appoint.id);
                    }
                  }}
                >
                  Откажи
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  toggleVideoCall: () => dispatch(toggleVideoCall()),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UserUpcommingTable);
