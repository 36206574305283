import { call, takeEvery, takeLatest, put } from "redux-saga/effects";
import { UserTypes } from "../actions/user";
import { api } from "../api.js";

export const edinUserWatcher = function*() {
  yield takeLatest(UserTypes.EDIT_USER_INFO, editUserWorker);
};

export const editUserWorker = function*(action) {
  if (action) {
    let response = yield api.editUserInfo(action.payload);
    if (response.status === 200) {
      // response = yield response.json()
      yield put({ type: UserTypes.EDIT_USER_INFO_SUCCESS, payload: response });
    }
  }
};

export const getAllDepartmentWatcher = function*(action) {
  yield takeEvery(UserTypes.GET_ALL_DEPARTMENTS, getAllDepartments);
};

export const getAllDepartments = function*() {
  let response = yield call(api.getDepartments);
  if (response.status === 200) {
    response = yield response.json();
    yield put({
      type: UserTypes.GET_ALL_DEPARTMENTS_SUCCESS,
      payload: response,
    });
  }
};

export const getGraphicsWatcher = function*() {
  yield takeEvery(UserTypes.GET_ALL_GRAPHICS, getGraphicsWorker);
};

export const getGraphicsWorker = function*(action) {
  if (action) {
    try {
      let response = yield api.getGraphics(action.payload);
      if (response.status === 200) {
        response = yield response.json();
        yield put({
          type: UserTypes.GET_ALL_GRAPHICS_SUCCESS,
          payload: response,
        });
      } else
        yield put({ type: UserTypes.GET_ALL_GRAPHICS_SUCCESS, payload: [] });
    } catch (err) {
      console.log("err", err);
    }
  }
};

export const makeAppointmentWatcher = function*() {
  yield takeLatest(UserTypes.MAKE_APPOINTMENT, makeAppointmentWorker);
};

export const makeAppointmentWorker = function*(action) {
  if (action) {
    let response = yield api.makeAppointment(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: UserTypes.MAKE_APPOINTMENT_SUCCESS,
        payload: action.payload.graphic_id,
      });
    } else if (response.status === 409) {
    }
  }
};

export const getAppointmentWatcher = function*() {
  yield takeLatest(UserTypes.GET_ALL_APPOINTMENTS, getAppointmentWorker);
};

export const getAppointmentWorker = function*(action) {
  if (action) {
    let response = yield api.getAppointments(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: UserTypes.GET_ALL_APPOINTMENTS_SUCCESS,
        payload: response,
      });
    }
  }
};

export const getCalendarPropsWatcher = function*() {
  yield takeLatest(UserTypes.GET_CALENDAR_PROPS, getCalendarPropsWorker);
};

export const getCalendarPropsWorker = function*(action) {
  if (action) {
    let response = yield api.getCalendarProps(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: UserTypes.GET_CALENDAR_PROPS_SUCCESS,
        payload: response,
      });
    }
  }
};
