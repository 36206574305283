import React from "react";
import { connect } from "react-redux";
import { toggleUserModal } from "../../actions/user";
import { editScheduleHour } from "../../actions/schedule";
import { makeGetUser, makeGetSchedule } from "../../selectors/selectors";
import moment from "moment";
import "./styles.scss";

const SchedulesTable = (props) => {
  const graphics = props.schedule.graphics
    ? props.schedule.graphics.graphics_templates
    : [];

  const renderField = (type) => {
    return (
      <span className={type ? "available" : "unavailable"}>
        {type ? "Активен" : "Неактивен"}
      </span>
    );
  };

  const renderSavedSchedule = (appoint, i) => {
    if (!appoint.enabled) {
      return (
        <tr
          style={i === graphics.length - 1 ? { borderBottom: "none" } : {}}
          key={i}
        >
          <td className="hour">{moment(appoint.start_hour).format("HH:mm")}</td>
          <td
            className="not-used"
            onClick={() =>
              props.editScheduleHour({ id: appoint.id, type: appoint.type })
            }
          >
            Неактивен
          </td>
        </tr>
      );
    } else
      return (
        <tr
          style={i === graphics.length - 1 ? { borderBottom: "none" } : {}}
          key={i}
        >
          <td className="hour">{moment(appoint.start_hour).format("HH:mm")}</td>
          <td
            className="used"
            onClick={() =>
              props.editScheduleHour({ id: appoint.id, type: appoint.type })
            }
          >
            Активен
          </td>
        </tr>
      );
  };

  const renderEditSchedule = (appoint, i) => {
    return (
      <tr
        style={i === graphics.length - 1 ? { borderBottom: "none" } : {}}
        key={i}
      >
        <td className="hour">{moment(appoint.start_hour).format("HH:mm")}</td>
        <td>{renderField(appoint.enabled)}</td>
      </tr>
    );
  };

  return (
    <table className="schedules-table">
      <thead>
        <tr>
          <th>Час</th>
          <th>{props.isEditable ? "ДЕЙСТВИЕ" : "СТАТУС"}</th>
        </tr>
      </thead>
      <tbody>
        {props.schedule.graphics &&
          props.schedule.graphics.graphics_templates &&
          props.schedule.graphics.graphics_templates.map((graphic, i) => {
            return props.isEditable
              ? renderSavedSchedule(graphic, i)
              : renderEditSchedule(graphic, i);
          })}
      </tbody>
    </table>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  editScheduleHour: (payload) => dispatch(editScheduleHour(payload)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(SchedulesTable);
