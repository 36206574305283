import { createSelector } from 'reselect'

const getUser = (state, props) => {
  return state.user
}

const getSchedule = (state, props) => {
  return state.schedule
}


export const makeGetUser = () => createSelector(
  [getUser],
  (user) => user,
)

export const makeGetSchedule = () => createSelector(
  [getSchedule],
  (schedule) => schedule
)