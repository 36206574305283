import authManager from "./utils/authManager";
const URL = process.env.REACT_APP_BE_API;

const getUserInfo = () => {
  return fetch(`${URL}/Auth/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const editUserInfo = (payload) => {
  return fetch(`${URL}/Auth/update?userId=${payload.user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
    body: JSON.stringify(payload),
  });
};

const getForgotPassword = (payload) => {
  return fetch(`${URL}/Auth/forgot-password?email=${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const editScheduleHour = (payload) => {
  return fetch(
    `${URL}/Graphics/toggle-enabled?graphicId=${payload.id}&type=${payload.type}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
    }
  );
};

const getDepartments = () => {
  return fetch(`${URL}/Departments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const getGraphics = (payload) => {
  return fetch(
    `${URL}/Graphics?departmentId=${payload.id}&date=${payload.date}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
    }
  );
};

const makeAppointment = (payload) => {
  return fetch(`${URL}/Meetings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
    body: JSON.stringify(payload),
  });
};

const approveAppointment = (payload) => {
  return fetch(`${URL}/Meetings/approve?meetingId=${payload}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const createSchedule = (payload) => {
  let formatedPayload = {
    for_weekday: payload.for_weekday,
    for_date: payload.for_date,
    duration: payload.duration,
    interval: payload.interval,
    start_time: payload.start_hour,
    end_time: payload.end_hour,
    note: payload.note,
  };
  return fetch(
    `${URL}/Graphics/generate?departmentId=${payload.departmentId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
      body: JSON.stringify(formatedPayload),
    }
  );
};

const getAppointments = (payload) => {
  return fetch(`${URL}/Meetings/user?userId=${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const getCalendarProps = (payload) => {
  return fetch(`${URL}/CalendarProps?departmentId=${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const getAdminGraphics = (payload) => {
  return fetch(
    `${URL}/Graphics/admin?departmentId=${payload.id}&&date=${payload.date}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
    }
  );
};

const getAdminUnapprovedMeetings = (payload) => {
  return fetch(`${URL}/Meetings/unapproved?departmentId=${payload.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const getAdminApprovedMeetings = (payload) => {
  return fetch(
    `${URL}/Meetings/${
      payload.all ? "upcoming" : "all-for-today"
    }?departmentId=${payload.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
    }
  );
};

const deleteSchedule = (payload) => {
  return fetch(
    `${URL}/Graphics?departmentId=${payload.departmentId}&date=${payload.date}&type=${payload.type}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
    }
  );
};

const cancelMeeting = (payload) => {
  return fetch(`${URL}/Meetings/cancel?meetingId=${payload.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
    body: JSON.stringify({ cancelation_reason: payload.cancelation_reason }),
  });
};

const getCurrentMeetings = (departmentId) => {
  return fetch(
    `${URL}/Meetings/next-three-for-today?departmentId=${departmentId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};

const getAllUnapprovedMeetings = () => {
  return fetch(`${URL}/Meetings/unapproved-count-all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const startMeeting = (payload) => {
  return fetch(`${URL}/Meetings/set-started?meetingId=${payload}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const endMeeting = (payload) => {
  return fetch(`${URL}/Meetings/set-passed?meetingId=${payload}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authManager.getJwt()}`,
    },
  });
};

const getDaysWithSchedules = (payload) => {
  return fetch(
    `${URL}/Graphics/graphicdays?departmentId=${payload.departmentId}&month=${payload.month}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authManager.getJwt()}`,
      },
    }
  );
};

export const api = {
  getUserInfo,
  editUserInfo,
  getForgotPassword,
  editScheduleHour,
  getDepartments,
  getGraphics,
  makeAppointment,
  approveAppointment,
  createSchedule,
  getAppointments,
  getCalendarProps,
  getAdminGraphics,
  getAdminUnapprovedMeetings,
  getAdminApprovedMeetings,
  deleteSchedule,
  cancelMeeting,
  getAllUnapprovedMeetings,
  getCurrentMeetings,
  startMeeting,
  endMeeting,
  getDaysWithSchedules,
};
