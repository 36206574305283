const themeProps = {
    palette: {
        common: {
            primaryJsColor1: '#212121',
            primaryJsColor2: '#efefef',
            headerColor: "#008F60",
            sidebarBgColor: "#ffffff",
            sidebarTitlesColor: "#000000",
            sidebarMenuItemsColor: "#008F60",
            sidebarMenuItemPressed: "#008F60",
            pageTitles: "#000000",
            mainContainerBgColor: "#efefef",
            tableTitleTextColor: "#000000",
            commonBtnBgColor: "#008F60",
            commonBtnHoverBgColor: "#008F60",
            commonBgColor: "#008F60"
        },
        primary: {
            light: '#3f4d67',
            main: '#3f4d67',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#f5f5f5',
            main: '#f4f7fa',
            contrastText: '#212121'
        },
        info: {
            light: '#f5f5f5',
            main: '#9e9e9e',
            dark: '#bdbdbd',
            contrastText: '#ffffff'
        },
        error: {
            main: '#da291c'
        }
    },
    overrides: {
        RaLayout: {
            root: {
                backgroundColor: '#f4f7fa'
            },
            appFrame: {
                flexGrow: 1
            },
            content: {
                backgroundColor: '#f4f7fa',
                zIndex: 1,
                '@media all and (min-width: 600px)': {
                    paddingLeft: '24px'
                }
            }
        },
        RaSidebar: {
            drawerPaper: {
                backgroundColor: '#3f4d67',
                position: 'sticky',
                marginTop: '-48px',
                height: 'calc(100% + 48px)',

                '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
                    position: 'relative'
                },
                '@media (min-width:960px)': {
                    marginTop: '-48px'
                },
                '@media (min-width:0px) and (max-width:599.95px)': {
                    backgroundColor: '#3f4d67'
                }
            }
        },
        RaMenuItemLink: {
            root: {
                color: 'rgba(255, 255, 255, 0.54)',
                whiteSpace: 'nowrap',
                minHeight: '2.5rem'
            },
            active: {
                color: 'rgba(255, 255, 255, 1)',
                RaMenuItemLink: {
                    icon: {
                        color: 'rgba(255, 255, 255, 1)'
                    }
                }
            },
            icon: {
                color: 'inherit'
            }
        },
        RaSelectInput: {
            input: {
                minWidth: '100px'
            }
        },
        RaDatagrid: {
            row: {
                '& td.column-undefined:last-child': {
                    textAlign: 'center'
                }
            },
            headerCell: {
                backgroundColor: '#eaeaea',
                fontWeight: 'bold',
                fontSize: '0.875rem',
                lineHeight: '1rem'
            },
            rowCell: {
                lineHeight: '0.875rem !important',
                '& > span ': {
                    fontSize: '0.875rem !important'
                }
            }
        },
        RaButton: {
            smallIcon: {
                '& > svg.MuiSvgIcon-root': {
                    fontSize: '20px'
                }
            }
        },
        RaNotification: {
            error: {
                backgroundColor: '#da291c'
            }
        },
        MuiModal: {
            root: {
                zIndex: 2000,
            }
        },
        MuiAppBar: {
            positionFixed: {
                left: 0,
                right: 0,
                width: 'auto',
                boxShadow: 'none',
            }
        },
        MuiDrawer: {
            docked: {
                backgroundColor: '#3f4d67'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#f5f5f5',
                '&:hover': {
                    backgroundColor: '#f5f5f5'
                }
            }
        },
        MuiListItemIcon: {
            root: {
                color: 'rgba(255, 255, 255, 0.54)'
            }
        },
        MuiMenuItem: {
            root: {
                whiteSpace: 'normal'
            }
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'rgba(255, 255, 255, 0.54)'
                }
            },
            selectMenu: {
                whiteSpace: 'normal'
            }
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: '#3f4d67'
            }
        }
    }
}

export default themeProps;