import { createBrowserHistory } from 'history';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
// import { all, fork } from 'redux-saga/effects';

import { adminReducer, USER_LOGOUT } from 'react-admin';
import user from '../reducers/user'
import schedule from '../reducers/schedule'
import rootSaga from '../sagas/'

const history = createBrowserHistory({ basename: `${process.env.REACT_APP_BASENAME}` });

const createAdminStore = useReduxDevTools => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    user,
    schedule
  });

  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  // const saga = function* rootSaga() {
  //   yield all([adminSaga(dataProvider, authProvider)].map(fork));
  // };

  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      useReduxDevTools &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
      })) ||
    compose;

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history)
        // add your own middlewares here
      )
      // add your own enhancers here
    )
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = createAdminStore(process.env.REACT_APP_REDUX_TOOL_ENABLED === 'true');

export { store, history };
