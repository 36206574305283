import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createMuiTheme } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  isSingleDepartment,
  hasSelectedDepartment,
} from "../../../helpers/helpers";
import { theme } from "../../../utils/theme";

import Header from "../../Header/Header";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import Modal from "../../Modal/Modal";
import CustomizedSelects from "../../Select/Select";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { toggleUserModal, getAllDepartments } from "../../../actions/user";
import { getAdminApprovedMeetings } from "../../../actions/schedule";
import { makeGetUser, makeGetSchedule } from "../../../selectors/selectors";
import ToBeAppTable from "../../ToBeAppTable/ToBeAppTable";
import { useStyles } from "../../../utils/styles";
import MobileToBeAppTable from "../../MobileToBeAppTable/MobileToBeAppTable";
import "./styles.scss";

const Upcomming = ({ ...props }) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const classes = useStyles();
  const [department, setDepartment] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState(false);
  const isOneDepartment = props?.user?.departments?.length === 1;
  useEffect(() => {
    let x = hasSelectedDepartment(department);
    if (x) {
      props.getApprovedMeetings({ id: Number(department), all: true });
    }
  }, [department]);

  useEffect(() => {
    if (isOneDepartment) {
      setDepartment(props.user.departments[0].id);
      setDepartmentSelected(true);
    }
  }, [isOneDepartment, props.user.departments]);

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />

      <div
        className={!isMobile && classes.main}
        style={{ backgroundColor: "#efefef", minHeight: `${height}px` }}
      >
        {!isMobile && <AdminSidebar />}

        <div className={!isMobile && classes.contentContainer}>
          <div
            className={
              isMobile
                ? classes.mobileContentContainer
                : classes.innerContentContainer
            }
          >
            <div
              className={`${
                isMobile
                  ? classes.mobileContentContainerTitle
                  : classes.contentContainerTitle
              } fonts-regular`}
            >
              ПРЕДСТОЯЩИ СРЕЩИ
            </div>
            <div className="row-selects">
              <TextField
                select
                onChange={(e) => {
                  setDepartment(e.target.value);
                  setDepartmentSelected(true);
                }}
                fullWidth
                value={department}
                variant="outlined"
                InputProps={{
                  style: { borderRadius: "20px" },
                }}
              >
                {props.user.departments &&
                  props.user.departments.map((department, i) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.display_name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className={`${classes.dateText} fonts-regular`}>
              Предстоящи срещи
            </div>
            {isMobile
              ? departmentSelected && <MobileToBeAppTable upcomming />
              : departmentSelected && <ToBeAppTable upcomming />}
          </div>
        </div>
      </div>
      <Modal
        isOpen={props.user.isOpenSuccess}
        width={isMobile ? "100%" : "40%"}
        type="appoitmentApproved"
        title="Отказ"
        textContent={`${
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Часът е отказан"
            : "Срещата е отказана"
        }. Заявителят ще бъде уведомен с имейл.`}
        icon={require("../../../assets/images/check-success.svg")}
      />
      <Modal
        isOpen={props.schedule.cancelModalOpen}
        width={isMobile ? "100%" : "40%"}
        type="cancelAppointment"
        title={`Отказ на ${
          process.env.REACT_APP_INSTANCE === "cadastre" ? "час" : "среща"
        }`}
        icon={require("../../../assets/images/check.svg")}
      />
    </>
  );
};

Upcomming.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const UpcommingPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <Upcomming {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  getDepartments: () => dispatch(getAllDepartments()),
  getApprovedMeetings: (payload) => dispatch(getAdminApprovedMeetings(payload)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(UpcommingPage);
