import React from "react";
import { Redirect, Route } from "react-router-dom";
import authManager from "../../utils/authManager";
import UserPortalPage from "../UserPortal/CreateAppointment/UserPortal";
import Schedules from "../AdminPortal/Schedules/Schedules";
import LoginPage from "../Auth/LoginPage/LoginPage";
const Login = () => {
  const isAdmin =
    authManager.getJwt() && authManager.getPermissions() === "admin";
  const isUser = authManager.getJwt();
  if (window.location.pathname === "/undefined") {
    if (isAdmin) {
      return <Redirect to="/admin/schedules" component={Schedules} />;
    }
    if (isUser) {
      return <Redirect to="/user-portal" component={UserPortalPage} />;
    }
    // return <Redirect to="/login" component={LoginPage} />
  }
  // if (window.location.pathname !== '/undefined') {
  //   return <Redirect to="/register" component={RegisterPage} />
  // }
  return (
    <Route
      render={(props) => {
        return isUser ? (
          <UserPortalPage {...props} />
        ) : (
          <LoginPage {...props} />
        );
      }}
      exact
      path="/login"
      component={LoginPage}
      noLayout
    />
  );
};

export default Login;
