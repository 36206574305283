import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import {
  toggleUserModal,
  toggleSuccessModal,
  toggleVideoCall,
} from "../../actions/user";
import {
  approveMeeting,
  cancelMeeting,
  toggleCancelModal,
  startMeeting,
  endMeeting,
} from "../../actions/schedule";
import { makeGetUser, makeGetSchedule } from "../../selectors/selectors";
import moment from "moment";
import "./styles.scss";

const ToBeAppTable = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      {!props.upcomming
        ? props.schedule.unapprovedMeetings &&
          props.schedule.unapprovedMeetings.map((meeting, i) => {
            return (
              <table
                style={{ marginBottom: "3px" }}
                className={`mobile-history-table ${
                  props.passed ? "margin-bottom20" : ""
                }`}
              >
                <tbody>
                  <div key={i}>
                    <tr>
                      <th className="data-type">Дата</th>
                      <td className="hour">
                        {moment(meeting.start_hour).format("DD.MM.YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <th className="data-type">Час</th>
                      <td className="hour">
                        {moment(meeting.start_hour).format("HH:mm")}
                      </td>
                    </tr>
                    <tr>
                      <th className="data-type">Номер</th>
                      <td className="hour">{meeting.id}</td>
                    </tr>
                    <tr>
                      <th className="data-type">Тема</th>
                      <td className="hour">{meeting?.topic}</td>
                    </tr>
                    {process.env.REACT_APP_INSTANCE === "mladost" && (
                      <tr>
                        <th className="data-type">Номер на документ/ РМЛ</th>
                        <td className="hour">{meeting?.document_number_rml}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="data-type">Име на клиент</th>
                      <td className="hour">{meeting?.applicant}</td>
                    </tr>
                    <tr>
                      <th className="data-type">Дирекция или отдел</th>
                      <td className="hour">{meeting.department_name}</td>
                    </tr>

                    {!["cadastre", "pernik"].includes(
                      process.env.REACT_APP_INSTANCE
                    ) && (
                      <tr>
                        <th className="data-type">Онлайн</th>
                        <td className="hour">
                          {meeting.meeting_url && (
                            <a href={meeting.meeting_url}>
                              <img
                                className="online"
                                alt="online"
                                src={require("../../assets/images/camera.svg")}
                              />
                            </a>
                          )}
                        </td>
                      </tr>
                    )}

                    <Button
                      style={{ marginTop: "2px" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        if (props.upcomming) {
                          props.toggleSuccess();
                          props.cancelMeeting(meeting.id);
                        } else {
                          props.approveMeeting(meeting.id);
                          props.toggleSuccess();
                        }
                      }}
                    >
                      {props.upcomming ? "Откажи" : "Одобри"}
                    </Button>
                    <Button
                      style={{ marginTop: "2px" }}
                      fullWidth
                      variant="contained"
                      onClick={() => dispatch(toggleCancelModal(meeting.id))}
                    >
                      Откажи
                    </Button>
                  </div>
                </tbody>
              </table>
            );
          })
        : props.schedule.approvedMeetings &&
          props.schedule.approvedMeetings.map((meeting, i) => {
            return (
              <table
                style={{ marginBottom: "3px" }}
                className={`mobile-history-table ${
                  props.passed ? "margin-bottom20" : ""
                }`}
              >
                <tbody>
                  <div key={i}>
                    <tr>
                      <th className="data-type">Дата</th>
                      <td className="hour">
                        {moment(meeting.start_hour).format("DD.MM.YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <th className="data-type">Час</th>
                      <td className="hour">
                        {moment(meeting.start_hour).format("HH:mm")}
                      </td>
                    </tr>
                    <tr>
                      <th className="data-type">Номер</th>
                      <td className="hour">{meeting.id}</td>
                    </tr>
                    <tr>
                      <th className="data-type">Тема</th>
                      <td className="hour">{meeting?.topic}</td>
                    </tr>
                    {process.env.REACT_APP_INSTANCE === "mladost" && (
                      <tr>
                        <th className="data-type">Номер на документ/ РМЛ</th>
                        <td className="hour">{meeting?.document_number_rml}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="data-type">Име на клиент</th>
                      <td className="hour">{meeting?.applicant}</td>
                    </tr>
                    <tr>
                      <th className="data-type">Дирекция или отдел</th>
                      <td className="hour">{meeting.department_name}</td>
                    </tr>

                    {!["cadastre", "pernik"].includes(
                      process.env.REACT_APP_INSTANCE
                    ) && (
                      <tr>
                        <th className="data-type">Онлайн</th>
                        <td className="hour">
                          {meeting.meeting_url && (
                            <a href={meeting.meeting_url}>
                              <img
                                className="online"
                                alt="online"
                                src={require("../../assets/images/camera.svg")}
                              />
                            </a>
                          )}
                        </td>
                      </tr>
                    )}

                    {props.showStartStop ? (
                      <>
                        {!meeting.started &&
                          !props.schedule.approvedMeetings.find(
                            (x) => x.started && !x.passed
                          ) &&
                          !meeting.passed && (
                            <Button
                              style={{ marginTop: "2px" }}
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                props.startMeeting(meeting.id);
                              }}
                            >
                              Стартирай
                            </Button>
                          )}
                        {meeting.started && !meeting.passed && (
                          <Button
                            style={{ marginTop: "2px" }}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              props.endMeeting(meeting.id);
                            }}
                          >
                            Приключи
                          </Button>
                        )}

                        {!meeting.started && !meeting.passed && (
                          <Button
                            style={{ marginTop: "2px" }}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              if (props.upcomming) {
                                dispatch(toggleCancelModal(meeting.id));
                              } else {
                                props.approveMeeting(meeting.id);
                                props.toggleSuccess();
                              }
                            }}
                          >
                            {props.upcomming ? "Откажи" : "Одобри"}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        style={{ marginTop: "2px" }}
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          if (props.upcomming) {
                            dispatch(toggleCancelModal(meeting.id));
                          } else {
                            props.approveMeeting(meeting.id);
                            props.toggleSuccess();
                          }
                        }}
                      >
                        {props.upcomming ? "Откажи" : "Одобри"}
                      </Button>
                    )}
                  </div>
                </tbody>
              </table>
            );
          })}
    </>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  toggleVideoCall: () => dispatch(toggleVideoCall()),
  approveMeeting: (payload) => dispatch(approveMeeting(payload)),
  cancelMeeting: (payload) => dispatch(cancelMeeting(payload)),
  startMeeting: (payload) => dispatch(startMeeting(payload)),
  endMeeting: (payload) => dispatch(endMeeting(payload)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(ToBeAppTable);
