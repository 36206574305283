import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../../utils/theme";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Header from "../../Header/Header";
import UserSidebar from "../UserSidebar/UserSidebar";
import Modal from "../../Modal/Modal";
import UserUpcommingTable from "../../UserUpCommingTable/UserUpcommingTable";
import UserMobileTable from "../../UserMobileTable/UserMobileTable";
import HistoryTable from "../../HistoryTable/HistoryTable";
import { toggleUserModal, getAllAppointments } from "../../../actions/user";
import { makeGetUser } from "../../../selectors/selectors";
import authManager from "../../../utils/authManager";
import { useStyles } from "../../../utils/styles";

import "./styles.scss";

const AppointmensHistory = ({ ...props }) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const classes = useStyles();
  const [id, setId] = useState(null);

  useEffect(() => {
    props.getAppointments(authManager.getUserId());
  }, []);

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />
      <div className={`${classes.main} ${isMobile ? "height-auto" : ""}`}>
        {!isMobile && <UserSidebar />}

        <div
          className={`${classes.contentContainer} ${
            isMobile ? "width-100 flex-1" : ""
          }`}
        >
          <div
            className={`${classes.innerContentContainer} ${
              isMobile ? "padding-10" : ""
            }`}
          >
            <div
              className={`${classes.contentContainerTitle} ${
                isMobile ? "marginB-1" : ""
              } fonts-regular`}
            >
              История на моите{" "}
              {process.env.REACT_APP_INSTANCE === "cadastre"
                ? "часове"
                : "срещи"}
            </div>
            <div
              className={`${classes.dateText} ${
                isMobile ? "margin-top-0" : ""
              } fonts-regular`}
            >
              Предстоящи{" "}
              {process.env.REACT_APP_INSTANCE === "cadastre"
                ? "часове"
                : "срещи"}
            </div>
            {isMobile ? (
              <UserMobileTable
                onBook={() => props.toggleModal()}
                declineMeeting={(id) => {
                  setId(id);
                }}
              />
            ) : (
              <UserUpcommingTable
                onBook={() => props.toggleModal()}
                declineMeeting={(id) => {
                  setId(id);
                }}
              />
            )}
            <div className={`${classes.dateText} fonts-regular`}>
              Минали срещи
            </div>
            {isMobile ? (
              <UserMobileTable passed />
            ) : (
              <HistoryTable onBook={() => props.toggleModal()} />
            )}
          </div>
        </div>
      </div>
      <Modal
        decline
        isOpen={props.user.isOpenSuccess}
        width={isMobile ? "100%" : "40%"}
        type="userDecline"
        id={id && id.toString()}
        title={`Отказ от заявена ${
          process.env.REACT_APP_INSTANCE === "cadastre" ? "час" : "среща"
        }`}
        textContent={`Вие искате да откажете ${
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "заявеният си час"
            : "заявената си среща"
        }. Сигурени ли сте?`}
        icon={require("../../../assets/images/warning.svg")}
      />
      <Modal
        isOpen={props.user.isOpenDecline}
        width={isMobile ? "100%" : "40%"}
        type="appoitmentDeclined"
        title={`Отказ на ${
          process.env.REACT_APP_INSTANCE === "cadastre" ? "час" : "среща"
        }`}
        textContent={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Вашият час е отменен успешно."
            : "Вашата среща е отменена успешно."
        }
        icon={require("../../../assets/images/check-success.svg")}
      />
      <Modal
        isOpen={props.user.isOpenVideoCall}
        width={isMobile ? "100%" : "40%"}
        type="videoCall"
        title="Линк за видеоконферентна връзка"
        textContent='Натиснете бутона "Начало", за да започнете Вашата видеоконферентна връзка.'
        icon={require("../../../assets/images/camera.svg")}
      />
    </>
  );
};

AppointmensHistory.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const AppointmensHistoryPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <AppointmensHistory {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  getAppointments: (payload) => dispatch(getAllAppointments(payload)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AppointmensHistoryPage);
