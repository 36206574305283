import jwt_decode from "jwt-decode";

const apiUrl = process.env.REACT_APP_BE_API;

export const authManager = () => {
  const allowedNoUserRoutes = [
    "/current-meetings",
    "/register",
    "/forgotten-pass",
  ];
  const storage = localStorage;
  const jwtExpiry = 30 * 60 * 1000; //30 minutes
  const jwtUrl = `${apiUrl}/Auth/refresh`;

  let jwtUser = null;
  let jwtRefreshTimeout = null;
  let isRefreshing = false;

  const getJwtRefreshTimeout = () => jwtRefreshTimeout;

  const setJwt = (data) => {
    let { access_token, user_id } = data;
    const jwt_decoded = jwt_decode(access_token);
    if (
      !!jwt_decoded &&
      typeof (
        jwt_decoded[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ] !== undefined
      )
    ) {
      if (access_token !== getJwt()) {
        storage.setItem("c19b_j", access_token);
        storage.setItem("c19b_x", new Date().getTime() + jwtExpiry);
        user_id && storage.setItem("user_id", user_id);
        refreshJwt(jwtExpiry);
      }
      jwtUser = {
        permissions:
          jwt_decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ],
      };
      return true;
    } else {
      removeJwt();
      return false;
    }
  };

  const getJwt = () => {
    const token = storage.getItem("c19b_j");
    if (token) {
      return token;
    } else {
      if (
        window.location.pathname !== "/login" &&
        !allowedNoUserRoutes.includes(window.location.pathname)
      ) {
        window.location = "/login";
      }
    }
  };
  const getJwtExpire = () => storage.getItem("c19b_x");

  const getPermissions = () => {
    return !!jwtUser ? jwtUser.permissions : null;
  };
  const getUserId = () => {
    const userId = storage.getItem("user_id");
    if (userId) {
      return userId;
    } else {
      if (
        window.location.pathname !== "/login" &&
        !allowedNoUserRoutes.includes(window.location.pathname)
      ) {
        window.location = "/login";
      }
    }
  };

  const getIsRefreshing = () => isRefreshing;

  const removeJwt = () => {
    storage.removeItem("c19b_j");
    storage.removeItem("c19b_x");

    jwtUser = null;
  };

  const abordRefreshJwt = () => {
    if (jwtRefreshTimeout) {
      window.clearTimeout(jwtRefreshTimeout);
    }
  };

  const refreshJwt = (delay) => {
    abordRefreshJwt();
    jwtRefreshTimeout = window.setTimeout(
      getRefreshedJwt,
      delay > 10000 ? delay - 10000 : 0
    );
  };

  const getRefreshedJwt = async () => {
    const jwt = getJwt();
    if (!jwt) return false;
    const request = new Request(jwtUrl, {
      method: "GET",
      headers: new Headers([
        ["Content-Type", "application/json"],
        ["Authorization", `Bearer ${jwt}`],
      ]),
      credentials: "include",
    });

    fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          removeJwt();
          return { access_token: null };
        }
        return response.json();
      })
      .then(({ access_token }) => {
        if (access_token) {
          isRefreshing = true;
          setJwt({ access_token });
          isRefreshing = false;
        } else {
          removeJwt();
        }
      });
  };

  return {
    getJwt,
    getJwtExpire,

    getPermissions,
    getUserId,
    getIsRefreshing,

    setJwt,
    removeJwt,

    refreshJwt,
    getJwtRefreshTimeout,
  };
};

export default authManager();
