import React from 'react'
import './styles.scss'

const Input = (props) => {
  return (
    <div className={props.mainStyle ? `${props.mainStyle} input-main` : 'input-main'} >
      {
        props.icon &&
        <img alt='name' src={props.icon} className='input-icon' />
      }
      <input
        {...props}
        placeholder={props.placeholder || ''}
        className={`${props.className} input-container`}
        value={props.value}
        onChange={props.onChange}
      />
      {props.password &&
        <img
          alt='pass'
          className='pass-img'
          src={require('../../assets/images/eye.svg.svg')}
          onClick={props.onPassClick}
        />}
    </div>
  )
}

export default Input