import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Dialog, Button } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { Check, Clear } from "@material-ui/icons";
import { TextField, MenuItem, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../../utils/theme";

import Header from "../../Header/Header";
import Modal from "../../Modal/Modal";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import SchedulesTable from "../../SchedulesTable/SchedulesTable";

import {
  toggleUserModal,
  getAllDepartments,
  getCalendarProps,
} from "../../../actions/user";
import {
  toggleEditHour,
  editSchedule,
  getAdminGraphics,
  getAdminUnapprovedMeetings,
  clearGraphic,
  deleteSchedule,
} from "../../../actions/schedule";
import {
  isSingleDepartment,
  hasSelectedDepartment,
} from "../../../helpers/helpers";

import { makeGetUser, makeGetSchedule } from "../../../selectors/selectors";
import RenderCalendar from "../../Calendar/Calendar";
import RaisedButton from "../../RaisedButton/RaisedButton";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { isEmpty } from "lodash";
import moment from "moment";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.headerColor,
    height: "70px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerTitle: {
    color: theme.palette.common.primaryJsColor2,
    fontSize: "1.25rem",
  },
  main: {
    height: "100%",
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.common.mainContainerBgColor,
  },
  sidebar: {
    backgroundColor: theme.palette.common.sidebarBgColor,
    height: "100%",
    maxWidth: "20%",
    flex: "0 0 20%",
  },
  sidebarTextContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  sidebarText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "10px",
    color: theme.palette.common.sidebarTitlesColor,
    marginTop: "20px",
    textTransform: "uppercase",
  },
  sidebarTitle: {
    fontSize: "16px",
    color: theme.palette.common.sidebarTitlesColor,
    textTransform: "uppercase",
  },
  contentContainer: {
    minHeight: "100%",
    width: "100%",
    display: "flex",
  },
  innerContentContainer: {
    flex: 1.5,
    display: "flex",
    flexDirection: "column",
    padding: "0px 50px",
    marginBottom: "20px",
  },
  mobileContentContainer: {
    flex: 1.5,
    display: "flex",
    flexDirection: "column",
    margin: "15px",
  },
  contentContainerTitle: {
    fontSize: "24px",
    color: theme.palette.common.pageTitles,
    margin: "3rem 0",
  },
  mobileContentContainerTitle: {
    fontSize: "24px",
    color: theme.palette.common.pageTitles,
    margin: "3px 0",
  },
  sideButtonsContainer: {
    marginTop: "30px",
    minHeight: "calc(100vh - 70px)",
  },
  bntContainer: {
    padding: "5px 3rem",
  },
  pressed: {
    color: theme.palette.common.sidebarMenuItemPressed,
  },
  circle: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.commonBgColor,
  },
  dateText: {
    fontSize: "14px",
    color: theme.palette.common.tableTitleTextColor,
    margin: "20px 0 10px 15px",
  },
  calendar: {
    marginTop: "10px",
    flex: 1,
    padding: "40px",
  },
}));

const Schedules = (props) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const classes = useStyles();

  const [date, onDateSet] = useState(null);
  const [dateObj, onDateObjSet] = useState(null);
  const [department, setDepartment] = useState(null);
  const [isOneDepartment, setIsOneDepartment] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (props.user.departments.length === 0) {
      props.getDepartments();
    } else if (props.user.departments.length === 1) {
      setIsOneDepartment(props?.user?.departments?.length === 1);
      setDepartment(props?.user?.departments[0].id);
    }
  }, [props.user.departments]);

  useEffect(() => {
    return () => props.clearGraphic();
  }, []);

  useEffect(() => {
    let hasDepartment = hasSelectedDepartment(department);
    let hasDate = !isEmpty(date);
    if (hasDepartment && hasDate) {
      props.getAdminGraphics({ id: Number(department), date });
      props.getUnapprovedMeetings({ id: Number(department) });
    }
  }, [department, date]);

  const onDateChange = (dateObj) => {
    let date = moment(dateObj).format("MM/DD/YYYY");
    onDateSet(date);
    onDateObjSet(dateObj);
  };

  const tileDisabled = (obj) => {
    if (props.user.calendarProps.lenght !== 0) {
      let disable = props.user.calendarProps.findIndex(
        (x) =>
          Number(x.disabled) === obj.date.getDay() ||
          Date.parse(x.disabled) === obj.date.getTime()
      );
      return disable === -1 ? false : true;
    }
    return false;
  };

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />

      <div
        className={!isMobile && classes.main}
        style={{ backgroundColor: "#efefef", minHeight: `${height}px` }}
      >
        <div className={!isMobile && classes.contentContainer}>
          {!isMobile && <AdminSidebar />}
          <div
            className={
              isMobile
                ? classes.mobileContentContainer
                : classes.innerContentContainer
            }
          >
            <div
              className={`${
                isMobile
                  ? classes.mobileContentContainerTitle
                  : classes.contentContainerTitle
              } fonts-regular`}
            >
              Графици
            </div>
            <div className="row-selects">
              <TextField
                select
                onChange={(e) => setDepartment(e.target.value)}
                fullWidth
                value={department}
                variant="outlined"
                InputProps={{
                  style: { borderRadius: "20px" },
                }}
              >
                {props.user.departments &&
                  props.user.departments.map((department, i) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.display_name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            {isMobile && (
              <RenderCalendar
                departmentId={department}
                value={dateObj}
                tileDisabled={tileDisabled}
                onChange={onDateChange}
                comment={
                  props.schedule.graphics &&
                  props.schedule.graphics.graphics_templates &&
                  props.schedule.graphics.graphics_templates.length !== 0 &&
                  props.schedule.graphics.graphics_templates[0].note
                }
              />
            )}
            {isMobile && <div style={{ marginTop: "5px" }} />}
            <SchedulesTable
              schedule={props.schedule}
              isEditable={props.schedule.isScheduleEditable}
              onBook={() => props.toggleModal()}
            />

            {props.schedule.graphics &&
              props.schedule?.graphics.graphics_templates?.length !== 0 && (
                <>
                  <div style={isMobile ? { marginTop: "-25px" } : {}}>
                    <RaisedButton
                      className={
                        !isMobile
                          ? "save-btn create-graph-btn margin-right0"
                          : "save-btn margin-right0 width0"
                      }
                      text={
                        props.schedule.isScheduleEditable
                          ? "Запази"
                          : "Редактирай"
                      }
                      onClick={() => props.toggleSchedule()}
                    />
                  </div>
                  <div className="delete-row">
                    <img
                      className="delete-svg"
                      src={require("../../../assets/images/delete_outline-24px.svg")}
                      alt="checkbox"
                    />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setConfirmDeleteOpen(true)}
                    >
                      <span className="checkbox-label">
                        Изтриване на графика
                      </span>
                    </div>
                  </div>
                </>
              )}
          </div>
          {!isMobile && (
            <RenderCalendar
              departmentId={department}
              value={dateObj}
              tileDisabled={tileDisabled}
              onChange={onDateChange}
              comment={
                props.schedule.graphics &&
                props.schedule.graphics.graphics_templates &&
                props.schedule.graphics.graphics_templates.length !== 0 &&
                props.schedule.graphics.graphics_templates[0].note
              }
            />
          )}
        </div>
      </div>
      {/* <Modal
        isOpen={props.user.isOpenModal}
        width='40%'
        type='Schedules'
      /> */}
      <Modal
        isOpen={props.user.isOpenSuccess}
        width={isMobile ? "100%" : "40%"}
        type="success"
        title="Графикът е изтрит успешно."
        icon={require("../../../assets/images/check-success.svg")}
      />
      <Dialog open={confirmDeleteOpen}>
        <Typography style={{ margin: "15px" }} variant="subtitle1">
          Изтриването на графиците ще анулира бъдещи срещи, ако има такива.
        </Typography>
        <div
          style={{ display: "flex", justifyContent: "center", margin: "25px" }}
        >
          <RaisedButton
            className={"sucess-btn"}
            text="Потвърди"
            onClick={() => {
              setConfirmDeleteOpen(false);
              props.deleteSchedule({
                departmentId: department,
                date: date,
                type: props.schedule.type,
              });
            }}
          />
          <div style={{ marginLeft: "10px" }} />
          <RaisedButton
            className={"sucess-btn-no"}
            text="Откажи"
            onClick={() => setConfirmDeleteOpen(false)}
          />
        </div>
      </Dialog>
    </>
  );
};

Schedules.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const SchedulesPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <Schedules {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  getDepartments: () => dispatch(getAllDepartments()),
  toggleSchedule: () => dispatch(toggleEditHour()),
  editSchedule: (payload) => dispatch(editSchedule(payload)),
  getAdminGraphics: (payload) => dispatch(getAdminGraphics(payload)),
  getDisabledDays: (payload) => dispatch(getCalendarProps(payload)),
  getUnapprovedMeetings: (payload) =>
    dispatch(getAdminUnapprovedMeetings(payload)),
  deleteSchedule: (payload) => dispatch(deleteSchedule(payload)),
  clearGraphic: () => dispatch(clearGraphic(null)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(SchedulesPage);
