import * as React from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "../components/Auth/LoginPage/LoginPage";
import RegisterPage from "../components/Auth/RegisterPage/RegisterPage";
import ForgottenPass from "../components/Auth/ForgottenPass/ForgottenPass";
import UserPortalPage from "../components/UserPortal/CreateAppointment/UserPortal";
import MyProfilePage from "../components/UserPortal/MyProfile/MyProfile";
import AppointmensHistory from "../components/UserPortal/AppointmentsHistory/AppointmentsHistory";
import CreateAppointment from "../components/AdminPortal/CreateAppointment/CreateAppointment";
import CreateSchedule from "../components/AdminPortal/CreateSchedule/CreateSchedule";
import Schedules from "../components/AdminPortal/Schedules/Schedules";
import ToBeApprovedPage from "../components/AdminPortal/ToBeApprovedApp/ToBeApproved";
import UpCommingPage from "../components/AdminPortal/UpComingApp/UpCommingApp";
import DailyMeetings from "../components/AdminPortal/DailyMeetings/DailyMeetings";
import CurrentMeetingsPage from "../components/AdminPortal/CurrentMeetings/CurrentMeetings";
import UserSidebar from "../components/UserPortal/UserSidebar/UserSidebar";
import authManager from "../utils/authManager";
import AdminSidebar from "../components/AdminPortal/AdminSidebar/AdminSidebar";
import { cadastre, pernik, adminEmail } from "./constants";

const isAdmin =
  authManager.getJwt() && authManager.getPermissions() === "admin";
const isUser = authManager.getJwt();
const specialUser = localStorage.getItem("email");
const isSpecialUser = specialUser && specialUser === adminEmail;

const PrivateRoute = ({ children, ...rest }) => {
  const isAdmin =
    authManager.getJwt() && authManager.getPermissions() === "admin";
  const isUser = authManager.getJwt();

  return (
    <Switch>
      {<Route exact path="/" component={LoginPage} noLayout />}
      {<Route exact path="/login" component={LoginPage} noLayout />}
      {!isAdmin && (
        <Route exact path="/user-portal" component={UserPortalPage} />
      )}
      {isUser && !isAdmin && (
        <Route
          exact
          path="/my-appointments-history"
          component={AppointmensHistory}
        />
      )}
      {isUser && !isAdmin && (
        <Route exact path="/my-profile" component={MyProfilePage} />
      )}
      {isUser && (
        <Route
          exact
          path="/nav"
          component={isAdmin ? AdminSidebar : UserSidebar}
        />
      )}
      {isAdmin && (
        <Route
          exact
          path="/admin/create-appointment"
          component={CreateAppointment}
        />
      )}
      {isAdmin && process.env.REACT_APP_INSTANCE !== cadastre && (
        <Route exact path="/admin/create-schedule" component={CreateSchedule} />
      )}
      {process.env.REACT_APP_INSTANCE === cadastre && isSpecialUser && (
        <Route exact path="/admin/create-schedule" component={CreateSchedule} />
      )}
      {isAdmin && process.env.REACT_APP_INSTANCE !== cadastre && (
        <Route exact path="/admin/schedules" component={Schedules} />
      )}
      {process.env.REACT_APP_INSTANCE === cadastre && isSpecialUser && (
        <Route exact path="/admin/schedules" component={Schedules} />
      )}
      {isAdmin && process.env.REACT_APP_INSTANCE !== cadastre && (
        <Route
          exact
          path="/admin/to-be-approved"
          component={ToBeApprovedPage}
        />
      )}
      {isAdmin && (
        <Route exact path="/admin/upcomming" component={UpCommingPage} />
      )}
      {isAdmin && (
        <Route exact path="/admin/daily-meetings" component={DailyMeetings} />
      )}
      {[cadastre, pernik].includes(process.env.REACT_APP_INSTANCE) && (
        <Route
          exact
          path="/admin/current-meetings/:id"
          component={CurrentMeetingsPage}
        />
      )}
    </Switch>
  );
};

export default [
  <Route
    render={(props) => {
      return isAdmin ? <Schedules {...props} /> : <LoginPage {...props} />;
    }}
    exact
    path="/login"
    component={LoginPage}
    noLayout
  />,
  <Route
    render={(props) => {
      return isUser ? <UserPortalPage {...props} /> : <LoginPage {...props} />;
    }}
    exact
    path="/login"
    component={LoginPage}
    noLayout
  />,
  <Route exact path="/register" component={RegisterPage} noLayout />,
  <Route exact path="/forgotten-pass" component={ForgottenPass} noLayout />,
  <PrivateRoute exact path="/user-portal" component={UserPortalPage} />,
  <PrivateRoute exact path="/nav" component={UserSidebar} />,
  <PrivateRoute exact path="/my-profile" component={MyProfilePage} />,
  <PrivateRoute
    exact
    path="/my-appointments-history"
    component={AppointmensHistory}
  />,
  <PrivateRoute
    exact
    path="/admin/create-appointment"
    component={CreateAppointment}
  />,
  <PrivateRoute
    exact
    path="/admin/create-schedule"
    component={CreateSchedule}
  />,
  <PrivateRoute exact path="/admin/schedules" component={Schedules} />,
  <PrivateRoute
    exact
    path="/admin/to-be-approved"
    component={ToBeApprovedPage}
  />,
  <PrivateRoute exact path="/admin/upcomming" component={UpCommingPage} />,
  <PrivateRoute exact path="/admin/daily-meetings" component={DailyMeetings} />,
  <Route
    exact
    path="/admin/current-meetings/:id"
    component={CurrentMeetingsPage}
  />,
];
