import { takeEvery, put, takeLatest, all } from "redux-saga/effects";
import { ScheduleTypes } from "../actions/schedule";
import { UserTypes } from "../actions/user";
import { api } from "../api.js";

export const getAdminGraphicsWatcher = function*() {
  yield takeEvery(ScheduleTypes.GET_ADMIN_GRAPHICS, getAdminGraphicsWorker);
};

export const getAdminGraphicsWorker = function*(action) {
  if (action) {
    let response = yield api.getAdminGraphics(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.GET_ADMIN_GRAPHICS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: ScheduleTypes.GET_ADMIN_GRAPHICS_SUCCESS,
        payload: { graphics_templates: [] },
      });
    }
  }
};

export const getAdminUnapprovedMeetingsWatcher = function*() {
  yield takeEvery(
    ScheduleTypes.GET_ADMIN_UNAPPROVED_MEETINGS,
    getAdminUnapprovedMeetingsWorker
  );
};

export const getAdminUnapprovedMeetingsWorker = function*(action) {
  if (action) {
    let response = yield api.getAdminUnapprovedMeetings(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.GET_ADMIN_UNAPPROVED_MEETINGS_SUCCESS,
        payload: response,
      });
    }
    if (response.status === 204) {
      yield put({
        type: ScheduleTypes.GET_ADMIN_UNAPPROVED_MEETINGS_SUCCESS,
        payload: [],
      });
    }
  }
};

export const getAdminApprovedMeetingsWatcher = function*() {
  yield takeEvery(
    ScheduleTypes.GET_ADMIN_APPROVED_MEETINGS,
    getAdminApprovedMeetingsWorker
  );
};

export const getAdminApprovedMeetingsWorker = function*(action) {
  if (action) {
    let response = yield api.getAdminApprovedMeetings(action.payload);
    if ([200, 204].includes(response.status)) {
      yield put({
        type: ScheduleTypes.GET_ADMIN_APPROVED_MEETINGS_SUCCESS,
        payload: response.status === 200 ? yield response.json() : [],
      });
    }
  }
};

export const createScheduleWatcher = function*() {
  yield takeEvery(ScheduleTypes.CREATE_SCHEDULE, createScheduleWorker);
};

export const createScheduleWorker = function*(action) {
  if (action) {
    let response = yield api.createSchedule(action.payload);
    if (response.status === 200) {
      yield all([
        yield put({
          type: ScheduleTypes.CREATE_SCHEDULE_SUCCESS,
          payload: response,
        }),
        yield put({ type: UserTypes.TOGGLE_SUCCESS_MODAL, payload: {} }),
      ]);
    }
    if (response.status === 409) {
      alert("Вече съществува графика за този ден!");
    }
  }
};

export const approveMeetingWatcher = function*() {
  yield takeEvery(ScheduleTypes.APPROVE_MEETING, approveMeetingWorker);
};

export const approveMeetingWorker = function*(action) {
  if (action) {
    let response = yield api.approveAppointment(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.APPROVE_MEETING_SUCCESS,
        payload: response,
      });
    }
  }
};

export const editScheduleHourWatcher = function*() {
  yield takeEvery(ScheduleTypes.EDIT_SCHEDULE_HOUR, editScheduleHourWorker);
};

export const editScheduleHourWorker = function*(action) {
  if (action) {
    let response = yield api.editScheduleHour(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.EDIT_SCHEDULE_HOUR_SUCCESS,
        payload: response,
      });
    }
  }
};

export const deleteScheduleWatcher = function*() {
  yield takeEvery(ScheduleTypes.DELETE_SCHEDULE, deleteScheduleWorker);
};

export const deleteScheduleWorker = function*(action) {
  if (action) {
    let response = yield api.deleteSchedule(action.payload);
    if (response.status === 200) {
      // response = yield response.json()
      yield all([
        put({ type: ScheduleTypes.DELETE_SCHEDULE_SUCCESS, payload: response }),
        put({ type: UserTypes.TOGGLE_SUCCESS_MODAL, payload: {} }),
      ]);
    }
  }
};

export const cancelMeetingWatcher = function*() {
  yield takeLatest(ScheduleTypes.CANCEL_MEETING, cancelMeetingWorker);
};

export const cancelMeetingWorker = function*(action) {
  if (action) {
    let response = yield api.cancelMeeting(action.payload);
    if (response.status === 200) {
      yield put({
        type: ScheduleTypes.CANCEL_MEETING_SUCCESS,
        payload: action.payload,
      });
    }
  }
};

export const getCurrentMeetingsWatcher = function*() {
  yield takeEvery(ScheduleTypes.CURRENT_MEETINGS, getCurrentMeetingsWorker);
};

export const getCurrentMeetingsWorker = function*(action) {
  if (action) {
    let response = yield api.getCurrentMeetings(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.CURRENT_MEETINGS_SUCCESS,
        payload: response,
      });
    }
    if (response.status === 204) {
      response = [];
      yield put({
        type: ScheduleTypes.CURRENT_MEETINGS_SUCCESS,
        payload: response,
      });
    }
  }
};

export const getAllUnapprovedMeetingsWatcher = function*() {
  yield takeEvery(
    ScheduleTypes.ALL_UNAPPROVED_MEETINGS,
    getAllUnapprovedMeetingsWorker
  );
};

export const getAllUnapprovedMeetingsWorker = function*(action) {
  if (action) {
    let response = yield api.getAllUnapprovedMeetings();
    if ([200, 204].includes(response.status)) {
      yield put({
        type: ScheduleTypes.ALL_UNAPPROVED_MEETINGS_SUCCESS,
        payload: response.status === 200 ? yield response.json() : [],
      });
    }
  }
};

export const startMeetingWatcher = function*() {
  yield takeLatest(ScheduleTypes.START_MEETING, startMeetingWorker);
};

export const startMeetingWorker = function*(action) {
  if (action) {
    let response = yield api.startMeeting(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.START_MEETING_SUCCESS,
        payload: response,
      });
    }
    if (response.status === 409) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.START_MEETING_FAIL,
        payload: response,
      });
    }
  }
};

export const endMeetingWatcher = function*() {
  yield takeLatest(ScheduleTypes.END_MEETING, endMeetingWorker);
};

export const endMeetingWorker = function*(action) {
  if (action) {
    let response = yield api.endMeeting(action.payload);
    if (response.status === 200) {
      response = yield response.json();
      yield put({
        type: ScheduleTypes.END_MEETING_SUCCESS,
        payload: response,
      });
    }
  }
};
