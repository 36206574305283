import React from "react";
import { Provider } from "react-redux";
import { Admin } from "react-admin";

import { store, history } from "./utils/store";

import dataProvider from "./utils/dataProvider";
import authProvider from "./utils/authProvider";
import customRoutes from "./utils/customRoutes";
import AppLayout from "./components/Auth/Layout";

import LoginPage from "./components/Auth/LoginPage/LoginPage";

import Login from "./components/Resources";
import i18nProvider from "./utils/i18n";
const App = () => (
  <Provider store={store}>
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={LoginPage}
      layout={AppLayout}
      customRoutes={customRoutes}
      history={history}
      title="Reception Time Portal"
    >
      {<Login />}
    </Admin>
  </Provider>
);

export default App;
