import React, { useEffect, useState } from "react";
import NavButton from "../../NavButton/NavButton";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllUnapprovedMeetings } from "../../../actions/schedule";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import authProvider from "../../../utils/authProvider";
import {
  cadastre,
  pernik,
  adminEmail,
  serdika,
  mladost,
} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.common.sidebarBgColor,
    height: "100%",
    maxWidth: "20%",
    flex: "0 0 20%",
  },
  mobileSidebar: {
    backgroundColor: theme.palette.common.sidebarBgColor,
    height: "100%",
  },
  sidebarTextContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  sidebarText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "10px",
    color: theme.palette.common.sidebarTitlesColor,
    marginTop: "20px",
    textTransform: "uppercase",
  },
  sidebarTitle: {
    textAlign: "center",
    fontSize: "16px",
    color: theme.palette.common.sidebarTitlesColor,
    textTransform: "uppercase",
  },
  contentContainer: {
    minHeight: "100%",
    maxWidth: "80%",
    flex: "0 0 80%",
    display: "flex",
  },
  sideButtonsContainer: {
    marginTop: "30px",
    minHeight: "calc(100vh - 70px)",
  },
  bntContainer: {
    padding: "5px 3rem",
  },
  mobileBntContainer: {
    padding: "5px 3rem",
    justifyContent: "center",
    textAlign: "center",
  },
  pressed: {
    color: theme.palette.common.sidebarMenuItemPressed,
  },
}));

const AdminSidebar = () => {
  const specialUser = localStorage.getItem("email");
  const isSpecialUser = specialUser && specialUser === adminEmail;
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const classes = useStyles();
  const history = useHistory();
  const location = window.location.pathname;
  const unapprovedMeetingsCount = useSelector(
    (state) => state.schedule.unapprovedMeetingsCount
  );
  const departments = useSelector((state) => state.user.departments) || [];
  const [interval, setInterval] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUnapprovedMeetings());
    const interval = setTimeout(() => {
      dispatch(getAllUnapprovedMeetings());
    }, 60 * 1000);
    setInterval(interval);
  }, [dispatch]);
  useEffect(() => {
    return () => clearTimeout(interval);
  }, [interval]);
  /// Meetings/unapproved-count-all
  let sidebarTitle = "",
    sidebarSubtitle = "",
    sidebarLogo = "";

  switch (process.env.REACT_APP_INSTANCE) {
    case cadastre:
      sidebarTitle = "Агенция по";
      sidebarSubtitle = "Геодезия, картография и кадастър";
      sidebarLogo = require("../../../assets/images/cadastre-logo.PNG");
      break;
    case serdika:
      sidebarTitle = "Столична община";
      sidebarSubtitle = 'Район "Сердика"';
      sidebarLogo = require("../../../assets/images/gerb-sofia.png");
      break;
    case pernik:
      sidebarTitle = "Община";
      sidebarSubtitle = "Перник";
      sidebarLogo = require("../../../assets/images/pernik-logo.jpg");
      break;
    case mladost:
      sidebarTitle = "Столична община";
      sidebarSubtitle = 'Район "Младост"';
      sidebarLogo = require("../../../assets/images/mladost-logo.png");
      break;
    default:
      throw Error("Should never happen");
  }

  return (
    <div
      className={location === "/nav" ? classes.mobileSidebar : classes.sidebar}
    >
      <div className={classes.sidebarTextContainer}>
        <img alt="logo" src={sidebarLogo} />
        <div
          className={`${
            process.env.REACT_APP_INSTANCE === cadastre
              ? classes.sidebarTitle
              : classes.sidebarText
          } fonts-regular`}
        >
          {sidebarTitle}
          <div className={`${classes.sidebarTitle} fonts-regular`}>
            {sidebarSubtitle}
          </div>
        </div>
      </div>
      <div className={classes.sideButtonsContainer}>
        <NavButton
          styles={isMobile ? classes.mobileBntContainer : classes.bntContainer}
          linkStyle={
            location === "/admin/create-appointment"
              ? `${classes.linkStyle} ${classes.pressed} `
              : classes.linkStyle
          }
          text={
            process.env.REACT_APP_INSTANCE === cadastre
              ? "Записване на час"
              : "Заяви среща"
          }
          onClick={() => {
            history.push("/admin/create-appointment");
          }}
        />
        {process.env.REACT_APP_INSTANCE !== cadastre && (
          <NavButton
            styles={
              isMobile ? classes.mobileBntContainer : classes.bntContainer
            }
            linkStyle={
              location === "/admin/create-schedule"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            text="Създай график"
            onClick={() => {
              history.push("/admin/create-schedule");
            }}
          />
        )}
        {process.env.REACT_APP_INSTANCE === cadastre && isSpecialUser && (
          <NavButton
            styles={
              isMobile ? classes.mobileBntContainer : classes.bntContainer
            }
            linkStyle={
              location === "/admin/create-schedule"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            text="Създай график"
            onClick={() => {
              history.push("/admin/create-schedule");
            }}
          />
        )}
        {process.env.REACT_APP_INSTANCE !== cadastre && (
          <NavButton
            styles={
              isMobile ? classes.mobileBntContainer : classes.bntContainer
            }
            linkStyle={
              location === "/admin/schedules"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            text="Графици"
            onClick={() => {
              history.push("/admin/schedules");
            }}
          />
        )}
        {process.env.REACT_APP_INSTANCE === cadastre && isSpecialUser && (
          <NavButton
            styles={
              isMobile ? classes.mobileBntContainer : classes.bntContainer
            }
            linkStyle={
              location === "/admin/schedules"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            text="Графици"
            onClick={() => {
              history.push("/admin/schedules");
            }}
          />
        )}
        {process.env.REACT_APP_INSTANCE !== cadastre && (
          <div className={isMobile ? "nav-row justify-center" : "nav-row"}>
            <NavButton
              styles={classes.bntContainer}
              linkStyle={
                location === "/admin/to-be-approved"
                  ? `${classes.linkStyle} ${classes.pressed} `
                  : classes.linkStyle
              }
              text="Срещи за одобрение"
              onClick={() => {
                history.push("/admin/to-be-approved");
              }}
            >
              <div className="app-to-approve">
                <span className="margintop1">{unapprovedMeetingsCount}</span>
              </div>
            </NavButton>
          </div>
        )}
        <NavButton
          styles={isMobile ? classes.mobileBntContainer : classes.bntContainer}
          linkStyle={
            location === "/admin/upcomming"
              ? `${classes.linkStyle} ${classes.pressed} `
              : classes.linkStyle
          }
          text="Предстоящи срещи"
          onClick={() => {
            history.push("/admin/upcomming");
          }}
        />
        <NavButton
          styles={isMobile ? classes.mobileBntContainer : classes.bntContainer}
          linkStyle={
            location === "/admin/daily-meetings"
              ? `${classes.linkStyle} ${classes.pressed} `
              : classes.linkStyle
          }
          text="Срещи за деня"
          onClick={() => {
            history.push("/admin/daily-meetings");
          }}
        />
        {[cadastre, pernik].includes(process.env.REACT_APP_INSTANCE) &&
          departments.length > 0 &&
          departments.map((x) => (
            <NavButton
              styles={
                isMobile ? classes.mobileBntContainer : classes.bntContainer
              }
              linkStyle={
                location === `/admin/current-meetings/${x.id}`
                  ? `${classes.linkStyle} ${classes.pressed} `
                  : classes.linkStyle
              }
              text={`Текущи срещи - ${x.display_name}`}
              onClick={() => {
                history.push(`/admin/current-meetings/${x.id}`);
              }}
            />
          ))}
        {isMobile && (
          <NavButton
            text="Изход"
            styles={
              isMobile ? classes.mobileBntContainer : classes.bntContainer
            }
            linkStyle={
              location === "/my-profile"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            onClick={() => {
              authProvider.logout();
              history.push("/login");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminSidebar;
