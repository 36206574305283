import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { Link, useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Modal from "../../Modal/Modal";
import { makeGetUser } from "../../../selectors/selectors";
import { toggleErrorPass, toggleErrorDontMatch } from "../../../actions/user";

import { Notification } from "react-admin";

import { useTranslate, useNotify } from "ra-core";
import { connect } from "react-redux";

import authProvider from "../../../utils/authProvider";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { theme } from "../../../utils/theme";

const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.common.headerColor,
    color: theme.palette.primary.contrastText,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.secondary.light,
  },
  card: {
    minWidth: 300,
    marginTop: "5em",
  },
  title: {
    font: "25px solid black",
    textAlign: "center",
    padding: "20px 0px",
  },
  icon: {
    backgroundColor: theme.palette.common.headerColor,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primaryJsColor2,
  },
  loginNavContainer: {
    padding: "20px",
  },
  toLoginLink: {
    textDecoration: "none",
  },
  toLoginBtn: {
    color: "#007bff",
    marginLeft: "10px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  loginBtn: {
    backgroundColor: theme.palette.common.commonBtnBgColor,
    "&:hover": {
      backgroundColor: theme.palette.common.commonBtnHoverBgColor,
    },
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const Register = (props) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();

  const handleSubmit = (auth) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/;
    let result = regex.test(auth.password);
    if (auth.password !== auth.repeat_password) {
      props.toggleErrorDontMatch();
      return;
    }
    if (!result) {
      props.errorModal();
      return;
    }
    setLoading(true);
    authProvider
      .register(auth)
      .then(() => {
        setLoading(false);
        notify("ra.auth.register_success", "success");
        history.push("/login");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.register_error"
            : error.message,
          "warning"
        );
      });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }

    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    if (!values.repeat_password) {
      errors.repeat_password = translate("ra.validation.required");
    }
    if (!values.name_surname) {
      errors.name_surname = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar variant="dense">
          <Avatar className={classes.icon}>
            <EventAvailableIcon />
          </Avatar>
          <Typography
            variant="body1"
            component="h1"
            align="center"
            style={{
              width: "100%",
            }}
          >
            {process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Записване на часове за подаване на заявления в СГКК София – град"
              : "Портал за заявяване на срещи с администрацията"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <Card className={classes.card}>
                <div className={classes.title}>Регистрация</div>
                {/* <div className={classes.hint}>Hint: test / test</div> */}
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="username"
                      component={renderInput}
                      label="Електронна поща"
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      // validate={(e) => validatePass(e)}
                      name="password"
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="repeat_password"
                      component={renderInput}
                      label={translate("ra.auth.repeat_password")}
                      type="password"
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="name_surname"
                      component={renderInput}
                      label={translate("ra.auth.name_surname")}
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    className={classes.loginBtn}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.register")}
                  </Button>
                </CardActions>
                <div className={classes.loginNavContainer}>
                  Вече имате акаунт?
                  <Link
                    className={classes.toLoginLink}
                    to={{ pathname: "/login" }}
                  >
                    <span className={classes.toLoginBtn}>Към вход</span>
                  </Link>
                </div>
              </Card>
              <Notification />
            </div>
          </form>
        )}
      />
      <Modal
        isOpen={props.user.isOpenErrorPass}
        width={isMobile ? "100%" : "40%"}
        type="errorPass"
        title="Грешка"
        textContent="Паролата трябва да съдържа поне 4 символа, малка буква, главна буква, цифра и специален символ!"
        icon={require("../../../assets/images/warning.svg")}
      />
      <Modal
        isOpen={props.user.isOpenErrorPassDontMatch}
        width={isMobile ? "100%" : "40%"}
        type="errorPassDontMatch"
        title="Грешка"
        textContent="Паролите не съвпадат!"
        icon={require("../../../assets/images/warning.svg")}
      />
    </>
  );
};

Register.propTypes = {
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const RegisterPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <Register {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  errorModal: () => dispatch(toggleErrorPass()),
  toggleErrorDontMatch: () => dispatch(toggleErrorDontMatch()),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(RegisterPage);
