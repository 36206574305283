import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMuiTheme } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../../utils/theme";

import Header from "../../Header/Header";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import Modal from "../../Modal/Modal";
import CustomTable from "../../Table/Table";
import RenderCalendar from "../../Calendar/Calendar";
import {
  toggleUserModal,
  getAllDepartments,
  getAllGraphics,
  clearUserGraphic,
  getCalendarProps,
} from "../../../actions/user";
import { makeGetUser, makeGetSchedule } from "../../../selectors/selectors";
import { isEmpty } from "lodash";
import moment from "moment";
import { useStyles } from "../../../utils/styles";
import "./styles.scss";
import { isSingleDepartment } from "../../../helpers/helpers";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const CreateAppointment = ({ ...props }) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const classes = useStyles();
  const [date, onDateSet] = useState(null);
  const [dateObj, onDateObjSet] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departmentName, setDepartmentName] = useState(
    "Избери дирекция или отдел"
  );
  const [graphicData, setGraphicData] = useState();
  const isOneDepartment = props?.user?.departments?.length === 1;

  useEffect(() => {
    props.getDepartments();
    return () => props.clearUserGraphic();
  }, []);
  useEffect(() => {
    if (isOneDepartment) {
      setDepartment(props.user.departments[0].id);
    }
  }, [isOneDepartment, props.user.departments]);

  useEffect(() => {
    if (department !== null) {
      props.getDisabledDays(Number(department));
    }
  }, [department]);

  useEffect(() => {
    let x = !isEmpty(department) || typeof department === "number";
    let y = !isEmpty(date);
    if (x && y) {
      props.getGraphics({ id: Number(department), date });
    }
  }, [department, date]);

  const onDateChange = (dateObj) => {
    let date = moment(dateObj).format("MM/DD/YYYY");
    onDateSet(date);
    onDateObjSet(dateObj);
  };

  const tileDisabled = (obj) => {
    if (props.user.calendarProps.lenght !== 0) {
      let disable = props.user.calendarProps.findIndex(
        (x) =>
          Number(x.disabled) === obj.date.getDay() ||
          Date.parse(x.disabled) === obj.date.getTime()
      );
      return disable === -1 ? false : true;
    }
    return false;
  };

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />

      <div
        className={!isMobile && classes.main}
        style={{ backgroundColor: "#efefef", minHeight: `${height}px` }}
      >
        {!isMobile && <AdminSidebar />}

        <div className={!isMobile && classes.contentContainer}>
          <div
            className={
              isMobile
                ? classes.mobileContentContainer
                : classes.innerContentContainer
            }
          >
            <div
              className={`${
                isMobile
                  ? classes.mobileContentContainerTitle
                  : classes.contentContainerTitle
              } fonts-regular`}
            >
              {process.env.REACT_APP_INSTANCE === "cadastre"
                ? "Записване на час"
                : "Заявка за среща"}
            </div>
            <TextField
              select
              onChange={(e) => {
                let nameValue = props.user.departments.filter(
                  (d) => d.id === Number(e.target.value)
                );
                setDepartment(e.target.value);
                let name = nameValue[0].display_name;
                setDepartmentName(name);
              }}
              fullWidth
              value={department}
              variant="outlined"
              InputProps={{
                style: { borderRadius: "20px" },
              }}
            >
              {props.user.departments &&
                props.user.departments.map((department, i) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.display_name}
                  </MenuItem>
                ))}
            </TextField>
            <div style={{ marginTop: "5px" }} />
            {isMobile && (
              <RenderCalendar
                departmentId={department}
                value={dateObj}
                tileDisabled={tileDisabled}
                onChange={onDateChange}
                comment={
                  props.user.graphics.length !== 0 &&
                  props.user.graphics[0].note
                }
              />
            )}
            {isMobile && <div style={{ marginTop: "5px" }} />}
            <CustomTable
              graphics={props.user.graphics}
              onBook={(graphic, userId) =>
                setGraphicData({ graphic, userId, departmentName })
              }
            />
          </div>
          {!isMobile && (
            <RenderCalendar
              departmentId={department}
              value={dateObj}
              tileDisabled={tileDisabled}
              onChange={onDateChange}
              comment={
                props.user.graphics.length !== 0 && props.user.graphics[0].note
              }
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={props.user.isOpenModal}
        width={isMobile ? "100%" : "40%"}
        type="createAppointment"
        payload={{ ...graphicData, department }}
        reload={() => props.getGraphics({ id: Number(department), date })}
      />
      <Modal
        isOpen={props.user.isOpenSuccess}
        width={isMobile ? "100%" : "40%"}
        type="appoitmentApproved"
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на час"
            : "Заявка за среща"
        }
        textContent={`${
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Успешно записахте час за приемно време."
            : "Вашата заявка за среща е подадена успешно. Очаквайте потвърждение."
        }`}
        icon={require("../../../assets/images/check-success.svg")}
      />
    </>
  );
};

CreateAppointment.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const CreateAppointmentPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <CreateAppointment {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  getDepartments: () => dispatch(getAllDepartments()),
  getGraphics: (payload) => dispatch(getAllGraphics(payload)),
  getDisabledDays: (payload) => dispatch(getCalendarProps(payload)),
  clearUserGraphic: () => dispatch(clearUserGraphic(null)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CreateAppointmentPage);
