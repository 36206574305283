export const ScheduleTypes = {
  TOGGLE_EDIT_HOUR: "TOGGLE_EDIT_HOUR",
  EDIT_SCHEDULE: "EDIT_SCHEDULE",
  EDIT_SCHEDULE_HOUR: "EDIT_SCHEDULE_HOUR",
  EDIT_SCHEDULE_HOUR_SUCCESS: "EDIT_SCHEDULE_HOUR_SUCCESS",
  CREATE_SCHEDULE: "CREATE_SCHEDULE",
  CREATE_SCHEDULE_SUCCESS: "CREATE_SCHEDULE_SUCCESS",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  DELETE_SCHEDULE_SUCCESS: "DELETE_SCHEDULE_SUCCESS",
  GET_ADMIN_GRAPHICS: "GET_ADMIN_GRAPHICS",
  GET_ADMIN_GRAPHICS_SUCCESS: "GET_ADMIN_GRAPHICS_SUCCESS",
  GET_ADMIN_UNAPPROVED_MEETINGS: "GET_ADMIN_UNAPPROVED_MEETINGS",
  GET_ADMIN_UNAPPROVED_MEETINGS_SUCCESS:
    "GET_ADMIN_UNAPPROVED_MEETINGS_SUCCESS",
  GET_ADMIN_APPROVED_MEETINGS: "GET_ADMIN_APPROVED_MEETINGS",
  GET_ADMIN_APPROVED_MEETINGS_SUCCESS: "GET_ADMIN_APPROVED_MEETINGS_SUCCESS",
  APPROVE_MEETING: "APPROVE_MEETING",
  APPROVE_MEETING_SUCCESS: "APPROVE_MEETING_SUCCESS",
  CANCEL_MEETING: "CANCEL_MEETING",
  CANCEL_MEETING_SUCCESS: "CANCEL_MEETING_SUCCESS",
  CLEAR_GRAPHIC: "CLEAR_GRAPHIC",
  CURRENT_MEETINGS: "CURRENT_MEETINGS",
  CURRENT_MEETINGS_SUCCESS: "CURRENT_MEETINGS_SUCCESS",
  ALL_UNAPPROVED_MEETINGS: "ALL_UNAPPROVED_MEETINGS",
  ALL_UNAPPROVED_MEETINGS_SUCCESS: "ALL_UNAPPROVED_MEETINGS_SUCCESS",
  CANCEL_MODAL: "CANCEL_MODAL",
  START_MEETING: "START_MEETING",
  START_MEETING_SUCCESS: "START_MEETING_SUCCESS",
  START_MEETING_FAIL: "START_MEETING_FAIL",
  START_MEETING_CLOSE_FAIL_MESSAGE: "START_MEETING_CLOSE_FAIL_MESSAGE",
  END_MEETING: "END_MEETING",
  END_MEETING_SUCCESS: "END_MEETING_SUCCESS",
};

export const toggleEditHour = (payload) => ({
  type: ScheduleTypes.TOGGLE_EDIT_HOUR,
  payload,
});

export const editSchedule = (payload) => ({
  type: ScheduleTypes.EDIT_SCHEDULE,
  payload,
});

export const editScheduleHour = (payload) => ({
  type: ScheduleTypes.EDIT_SCHEDULE_HOUR,
  payload,
});

export const createSchedule = (payload) => ({
  type: ScheduleTypes.CREATE_SCHEDULE,
  payload,
});

export const getAdminGraphics = (payload) => ({
  type: ScheduleTypes.GET_ADMIN_GRAPHICS,
  payload,
});

export const getAdminGraphicsSuccess = (payload) => ({
  type: ScheduleTypes.GET_ADMIN_GRAPHICS_SUCCESS,
  payload,
});

export const getAdminUnapprovedMeetings = (payload) => ({
  type: ScheduleTypes.GET_ADMIN_UNAPPROVED_MEETINGS,
  payload,
});

export const approveMeeting = (payload) => ({
  type: ScheduleTypes.APPROVE_MEETING,
  payload,
});

export const deleteSchedule = (payload) => ({
  type: ScheduleTypes.DELETE_SCHEDULE,
  payload,
});

export const cancelMeeting = (payload) => ({
  type: ScheduleTypes.CANCEL_MEETING,
  payload,
});

export const clearGraphic = (payload) => ({
  type: ScheduleTypes.CLEAR_GRAPHIC,
  payload,
});

export const getAdminApprovedMeetings = (payload) => ({
  type: ScheduleTypes.GET_ADMIN_APPROVED_MEETINGS,
  payload,
});

export const getCurrentMeetings = (payload) => ({
  type: ScheduleTypes.CURRENT_MEETINGS,
  payload,
});
export const getCurrentMeetingsSuccess = (payload) => ({
  type: ScheduleTypes.CURRENT_MEETINGS_SUCCESS,
  payload,
});

export const getAllUnapprovedMeetings = () => ({
  type: ScheduleTypes.ALL_UNAPPROVED_MEETINGS,
});
export const getAllUnapprovedMeetingsSuccess = (payload) => ({
  type: ScheduleTypes.ALL_UNAPPROVED_MEETINGS_SUCCESS,
  payload,
});
export const toggleCancelModal = (payload) => ({
  type: ScheduleTypes.CANCEL_MODAL,
  payload,
});
export const startMeeting = (payload) => ({
  type: ScheduleTypes.START_MEETING,
  payload,
});
export const endMeeting = (payload) => ({
  type: ScheduleTypes.END_MEETING,
  payload,
});
export const closeFailMessage = () => ({
  type: ScheduleTypes.START_MEETING_CLOSE_FAIL_MESSAGE,
});
