import { all } from "redux-saga/effects";
import {
  edinUserWatcher,
  editUserWorker,
  getAllDepartmentWatcher,
  getAllDepartments,
  getGraphicsWatcher,
  getGraphicsWorker,
  makeAppointmentWatcher,
  makeAppointmentWorker,
  getAppointmentWatcher,
  getAppointmentWorker,
  getCalendarPropsWatcher,
  getCalendarPropsWorker,
} from "./userData";
import {
  createScheduleWatcher,
  createScheduleWorker,
  getAdminGraphicsWatcher,
  getAdminGraphicsWorker,
  getAdminUnapprovedMeetingsWatcher,
  getAdminUnapprovedMeetingsWorker,
  getAdminApprovedMeetingsWatcher,
  getAdminApprovedMeetingsWorker,
  approveMeetingWatcher,
  approveMeetingWorker,
  editScheduleHourWatcher,
  editScheduleHourWorker,
  deleteScheduleWatcher,
  deleteScheduleWorker,
  cancelMeetingWatcher,
  cancelMeetingWorker,
  getCurrentMeetingsWatcher,
  getCurrentMeetingsWorker,
  getAllUnapprovedMeetingsWatcher,
  getAllUnapprovedMeetingsWorker,
  startMeetingWatcher,
  endMeetingWatcher,
} from "./schedule";
import authProvider from "../utils/authProvider";
import { authManager } from "../utils/authManager";
export default function* rootSaga() {
  yield all([
    editUserWorker(),
    edinUserWatcher(),
    getAllDepartmentWatcher(),
    getAllDepartments(),
    getGraphicsWatcher(),
    getGraphicsWorker(),
    makeAppointmentWatcher(),
    makeAppointmentWorker(),
    createScheduleWatcher(),
    createScheduleWorker(),
    getAppointmentWatcher(),
    getAppointmentWorker(),
    getCalendarPropsWatcher(),
    getCalendarPropsWorker(),
    getAdminGraphicsWatcher(),
    getAdminGraphicsWorker(),
    getAdminUnapprovedMeetingsWatcher(),
    getAdminUnapprovedMeetingsWorker(),
    getAdminApprovedMeetingsWatcher(),
    getAdminApprovedMeetingsWorker(),
    approveMeetingWatcher(),
    approveMeetingWorker(),
    editScheduleHourWatcher(),
    editScheduleHourWorker(),
    deleteScheduleWatcher(),
    deleteScheduleWorker(),
    cancelMeetingWatcher(),
    cancelMeetingWorker(),
    getCurrentMeetingsWatcher(),
    getCurrentMeetingsWorker(),
    getAllUnapprovedMeetingsWatcher(),
    getAllUnapprovedMeetingsWorker(),
    startMeetingWatcher(),
    endMeetingWatcher(),
    authManager,
    authProvider,
  ]);
}
