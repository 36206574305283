import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../../utils/theme";
import { makeGetSchedule } from "../../../selectors/selectors";
import { getCurrentMeetings } from "../../../actions/schedule";
import useInterval from "../../../hooks/useInterval";
import "./styles.scss";

const CurrentMeetings = (props) => {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentMeetings(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    dispatch(getCurrentMeetings(id));
  }, 10000);

  return (
    <>
      <table className="tobe-main-table">
        <thead>
          <tr>
            <th className="flex3">Номер</th>
            <th className="flex3">Начален час</th>
            <th className="flex3">
              {process.env.REACT_APP_INSTANCE === "cadastre" ? "Гише" : "Отдел"}
            </th>
            <th className="flex3">Информация</th>
          </tr>
        </thead>
        <tbody>
          {props?.schedule?.currentMeetings.map((x, i) => (
            <tr key={i}>
              <td className="hour flex3">{x.id}</td>
              <td className="hour flex3">{x.meeting_start_hour}</td>
              <td className="hour flex3">{x.department_name}</td>
              <td className="hour flex3">{x.system_message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const CurrentMeetingsPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <CurrentMeetings {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(CurrentMeetingsPage);
