import React from "react";
import { connect } from "react-redux";
import { toggleUserModal } from "../../actions/user";
import { makeGetUser } from "../../selectors/selectors";
import moment from "moment";
import "./styles.scss";

const HistoryTable = (props) => {
  return (
    <table className="main-history-table">
      <thead>
        <tr>
          <th style={{ flex: 1 }} className="data-type">
            Дата
          </th>
          <th style={{ flex: 1 }} className="data-type">
            Номер
          </th>
          <th style={{ flex: 3 }} className="data-type">
            Тема
          </th>
          <th style={{ flex: 1 }} className="data-type">
            Час
          </th>
          <th style={{ flex: 3 }} className="data-type">
            {process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Часът е проведен с"
              : "Срещата е проведена с"}
          </th>
          <th style={{ flex: 3 }} className="data-type">
            Статус
          </th>
        </tr>
      </thead>
      <tbody>
        {props.user.appointments &&
          props.user.appointments.past_meetings.map((appoint, i) => {
            return (
              <tr key={i}>
                <td style={{ flex: 1 }} className="hour">
                  {moment(appoint.start_hour).format("DD.MM.YYYY")}
                </td>
                <td style={{ flex: 1 }} className="hour">
                  {appoint.id}
                </td>
                <td style={{ flex: 3 }} className="hour">
                  {appoint.topic}
                </td>
                <td style={{ flex: 1 }} className="hour">
                  {moment(appoint.start_hour).format("HH:mm")}
                </td>
                <td style={{ flex: 3 }} className="hour">
                  {appoint.department_name}
                </td>
                <td style={{ flex: 3 }} className="hour">
                  <span className="rounded-white">{appoint.status}</span>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(HistoryTable);
