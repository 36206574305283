import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.headerColor,
    height: "70px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerTitle: {
    color: theme.palette.common.primaryJsColor2,
    fontSize: "1.25rem",
  },
  main: {
    height: "100%",
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.common.mainContainerBgColor,
  },
  sidebar: {
    backgroundColor: theme.palette.common.sidebarBgColor,
    height: "100%",
    maxWidth: "20%",
    flex: "0 0 20%",
  },
  sidebarTextContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  sidebarText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "10px",
    color: theme.palette.common.sidebarTitlesColor,
    marginTop: "20px",
    textTransform: "uppercase",
  },
  sidebarTitle: {
    fontSize: "16px",
    color: theme.palette.common.sidebarTitlesColor,
    textTransform: "uppercase",
  },
  contentContainer: {
    minHeight: "100%",
    maxWidth: "80%",
    flex: "0 0 80%",
    display: "flex",
  },
  mobileContentContainer: {
    flex: 1.5,
    display: "flex",
    flexDirection: "column",
    margin: "15px",
  },
  innerContentContainer: {
    flex: 1.5,
    display: "flex",
    flexDirection: "column",
    padding: "0px 50px",
  },
  contentContainerTitle: {
    fontSize: "24px",
    color: theme.palette.common.pageTitles,
    margin: "3rem 0",
  },
  mobileContentContainerTitle: {
    fontSize: "24px",
    color: theme.palette.common.pageTitles,
    margin: "3px 0",
  },
  sideButtonsContainer: {
    marginTop: "30px",
    minHeight: "calc(100vh - 70px)",
  },
  bntContainer: {
    padding: "5px 3rem",
  },
  pressed: {
    color: theme.palette.common.sidebarMenuItemPressed,
  },
  circle: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.commonBgColor,
  },
  dateText: {
    fontSize: "14px",
    color: theme.palette.common.tableTitleTextColor,
    margin: "20px 0 10px 0px",
  },
  calendar: {
    marginTop: "10px",
    flex: 1,
    padding: "40px",
  },
  nameContainer: {
    display: "flex",
    marginTop: "10px",
  },
  names: {
    marginLeft: 0,
  },
  names10: {
    marginLeft: "35px",
  },
  inputContainer: {
    margin: "20px 0 0 0px",
    maxWidth: "60%",
  },
  input: {
    marginTop: "15px",
  },
}));
