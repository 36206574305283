import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import authProvider from "../../utils/authProvider";
import authManager from "../../utils/authManager";
import { history } from "../../utils/store";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./styles.scss";

const Header = ({ ...props }) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  let location = window.location.pathname.substr(1, 5);
  const fName = localStorage.getItem("firstName");
  const lName = localStorage.getItem("lastName");
  const userName = `${fName.substr(0, 1)}${lName.substr(0, 1)}`;
  const role = authManager.getPermissions();

  let headerImage = "";

  switch (process.env.REACT_APP_INSTANCE) {
    case "cadastre":
      headerImage = require("../../assets/images/mrt-icon-cadastre.png");
      break;
    case "serdika":
      headerImage = require("../../assets/images/priemno-vreme-icon.svg");
      break;
    case "pernik":
      headerImage = require("../../assets/images/mrt-icon-pernik.png");
      break;
    case "mladost":
      headerImage = require("../../assets/images/mrt-icon-mladost.png");
      break;
    default:
      throw Error("should never happen");
  }
  return (
    <div className={props.style ? `${props.style} main` : "main"}>
      <div className="main-container-header">
        <div className="title-container">
          <img alt="calendar" src={headerImage} className="icon" />
          <div
            className={
              props.titleStyle
                ? `${props.titleStyle} header-title`
                : "header-title"
            }
          >
            {props.title ||
              (process.env.REACT_APP_INSTANCE === "cadastre"
                ? "Записване на часове за подаване на заявления в СГКК София – град"
                : "Портал приемно време")}
          </div>
          {location === "admin" && (
            <div className="admin-panel">Admin Panel</div>
          )}
        </div>
        {isMobile ? (
          <div className="header-inner-container">
            <img
              alt="mobile view"
              src={require("../../assets/images/hamburger-menu.svg")}
              className="mobile-menu"
              onClick={() => {
                if (location === "nav") {
                  history.push("/user-portal");
                } else {
                  history.push("/nav");
                }
              }}
            />
          </div>
        ) : (
          <div className="header-inner-container">
            <div className="user-circle">{userName || "ИФ"}</div>
            <div
              className="userName"
              onClick={() => {
                if (role !== "admin") history.push("/my-profile");
              }}
            >
              {`${fName} ${lName}` || "Име Фамилия"}
            </div>
            <ExitToAppIcon
              color="secondary"
              className="exit-icon"
              onClick={() => {
                authProvider.logout();
                history.push("/login");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
