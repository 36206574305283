import React from 'react'
import './styles.scss'

class CheckboxButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: this.props.checked || false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  render() {
    // const { checked } = this.state
    const { label, noLabel, red, className, labelClassName, checked } = this.props
    const checkboxStyles = []
    if (checked && red) {
      checkboxStyles.push(`check-box-image-container check-box-red-background ${className}`)
    } else if (checked) {
      checkboxStyles.push(`check-box-image-container checked-background ${className}`)
    } else if (red) {
      checkboxStyles.push(`check-box-image-container check-box-red-border ${className}`)
    } else {
      checkboxStyles.push(`check-box-image-container ${className}`)
    }
    return (
      <div
        className={`check-box-container ${checked ? 'checked' : ''} ${this.props.styles ? this.props.styles : ''} display:none`}
        onClick={() => {
          this.setState({ checked: !checked }, () => {
            if (this.props.onChange) {
              this.props.onChange.call(null, this.props.label)
            }
          })
        }}>

        <div className={checkboxStyles}>
          <div className='check-box-image'>
            <img
              className='check-box-image-check'
              src={require('../../assets/images/check.svg')}
              alt='checkbox'
            />
          </div>
        </div>
        {
          !noLabel &&
          <span className={red ? `check-box-text check-box-text-red ${labelClassName}` : `check-box-text ${labelClassName}`}>
            {label || 'Check box'}
          </span>
        }

      </div>
    )
  }
}

export default CheckboxButton
