import { ScheduleTypes } from "../actions/schedule";

const initialState = {
  isScheduleEditable: false,
  graphics: null,
  type: null,
  unapprovedMeetings: [],
  unapprovedMeetingsCount: 0,
  approvedMeetings: [],
  currentMeetings: [],
  cancelModalOpen: false,
  meetingToCancel: null,
  startFailed: undefined,
};

const editSchedule = (payload, state) => {
  let newSchedule = state.graphics.graphics_templates
    .slice(0)
    .map((hour, index) => {
      if (hour.hour === payload.hour) {
        return { hour: hour.hour, used: !hour.used };
      }
      return hour;
    });
  return newSchedule;
};

const editScheduleHour = (payload, state) => {
  let newSchedule = state.graphics.graphics_templates
    .slice(0)
    .map((hour, index) => {
      if (hour.id === payload.id) {
        return {
          ...hour,
          enabled: !hour.enabled,
        };
      }
      return hour;
    });
  return newSchedule;
};

const approveMeeting = (state, payload) => {
  return state.unapprovedMeetings.filter(
    (meeting) => meeting.id !== payload.id
  );
};

const editApprovedMeetings = (state, payload) => {
  return state.approvedMeetings.filter((meeting) => meeting.id !== payload.id);
};

const editUnapprovedMeetings = (state, payload) => {
  return state.unapprovedMeetings.filter(
    (meeting) => meeting.id !== payload.id
  );
};

const updateApprovedMeeting = (state, payload) => {
  const updatedMeetings = [...state.approvedMeetings];
  if (payload.passed) {
    updatedMeetings.splice(
      updatedMeetings.indexOf(updatedMeetings.find((x) => x.id === payload.id)),
      1
    );
  } else {
    updatedMeetings[
      updatedMeetings.indexOf(updatedMeetings.find((x) => x.id === payload.id))
    ] = payload;
  }

  return updatedMeetings;
};

const schedule = (state = initialState, { type, payload }) => {
  switch (type) {
    case ScheduleTypes.TOGGLE_EDIT_HOUR:
      return { ...state, isScheduleEditable: !state.isScheduleEditable };

    case ScheduleTypes.EDIT_SCHEDULE:
      return { ...state, currentSchedule: editSchedule(payload, state) };

    case ScheduleTypes.EDIT_SCHEDULE_HOUR:
      return {
        ...state,
        graphics: {
          ...state.graphics,
          graphics_templates: editScheduleHour(payload, state),
        },
      };

    case ScheduleTypes.GET_ADMIN_GRAPHICS_SUCCESS:
      if (
        payload &&
        payload.graphics_templates &&
        payload.graphics_templates.length !== 0
      ) {
        return {
          ...state,
          graphics: payload,
          type: payload.graphics_templates[0].type,
        };
      } else {
        return { ...state, graphics: payload };
      }

    case ScheduleTypes.GET_ADMIN_UNAPPROVED_MEETINGS_SUCCESS:
      return { ...state, unapprovedMeetings: payload };

    case ScheduleTypes.GET_ADMIN_APPROVED_MEETINGS_SUCCESS:
      return { ...state, approvedMeetings: payload.slice(0) };

    case ScheduleTypes.APPROVE_MEETING_SUCCESS:
      return { ...state, unapprovedMeetings: approveMeeting(state, payload) };

    case ScheduleTypes.CREATE_SCHEDULE_SUCCESS:
      return state;

    case ScheduleTypes.DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        graphics: {
          ...state.graphics,
          graphics_templates: [],
        },
      };

    case ScheduleTypes.CLEAR_GRAPHIC:
      return {
        ...state,
        graphics: null,
      };
    case ScheduleTypes.CANCEL_MEETING_SUCCESS:
      return {
        ...state,
        approvedMeetings: editApprovedMeetings(state, payload),
        unapprovedMeetings: editUnapprovedMeetings(state, payload),
      };

    case ScheduleTypes.CURRENT_MEETINGS_SUCCESS:
      return {
        ...state,
        currentMeetings: payload,
      };
    case ScheduleTypes.ALL_UNAPPROVED_MEETINGS_SUCCESS:
      return {
        ...state,
        unapprovedMeetingsCount: payload,
      };
    case ScheduleTypes.CANCEL_MODAL:
      return {
        ...state,
        cancelModalOpen: !state.cancelModalOpen,
        meetingToCancel: payload,
      };
    case ScheduleTypes.START_MEETING_SUCCESS:
      return {
        ...state,
        approvedMeetings: updateApprovedMeeting(state, payload),
      };
    case ScheduleTypes.START_MEETING_FAIL:
      return {
        ...state,
        startFailed: payload,
      };
    case ScheduleTypes.START_MEETING_CLOSE_FAIL_MESSAGE:
      return {
        ...state,
        startFailed: undefined,
      };
    case ScheduleTypes.END_MEETING_SUCCESS:
      return {
        ...state,
        approvedMeetings: updateApprovedMeeting(state, payload),
      };
    default:
      return state;
  }
};

export default schedule;
