import CreateAppointment from './CreateAppointment/CreateAppointmen'
import SuccessModal from './SuccessModal/SuccessModal'
import ErrorModal from './Error/ErrorModal'
import PassErrorModal from './PassError/PassError'
import CancelModal from "./CancelMeeting/CancelMeeting";
const Modals = {
  CreateAppointment,
  SuccessModal,
  ErrorModal,
  PassErrorModal,
  CancelModal
}

export default Modals