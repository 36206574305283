import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import RenderCalendar from "../../Calendar/Calendar";

import { theme } from "../../../utils/theme";

import Header from "../../Header/Header";
import UserSidebar from "../UserSidebar/UserSidebar";
import Modal from "../../Modal/Modal";
import CustomTable from "../../Table/Table";
import { CircularProgress, MenuItem, TextField } from "@material-ui/core";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import {
  toggleUserModal,
  getAllDepartments,
  getAllGraphics,
  getCalendarProps,
  setLoading,
  clearUserGraphic,
} from "../../../actions/user";
import { makeGetUser } from "../../../selectors/selectors";
import moment from "moment";
import { useStyles } from "../../../utils/styles";
import "./styles.scss";

const UserPortal = ({
  getDepartments,
  getDisabledDays,
  getGraphics,
  user,
  clearUserGraphic,
  setToggleLoading,
}) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  const classes = useStyles();
  const [date, onDateSet] = useState(null);
  const [dateObj, onDateObjSet] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departmentName, setDepartmentName] = useState(
    "Избери дирекция или отдел"
  );

  const [graphicData, setGraphicData] = useState();

  useEffect(() => {
    getDepartments();
    return () => clearUserGraphic();
  }, [getDepartments, clearUserGraphic]);

  useEffect(() => {
    if (department !== null) {
      getDisabledDays(Number(department));
    }
  }, [department, getDisabledDays]);

  useEffect(() => {
    if (department && date) {
      setToggleLoading();
      getGraphics({ id: Number(department), date });
    }
  }, [department, date, getGraphics, setToggleLoading]);

  useEffect(() => {
    const singleValue = user?.departments?.length === 1;
    if (singleValue) {
      setDepartment(user.departments[0].id);
      setDepartmentName(user.departments[0].display_name);
    }
  }, [user]);

  const onDateChange = (dateObj) => {
    let date = moment(dateObj).format("MM/DD/YYYY");
    onDateSet(date);
    onDateObjSet(dateObj);
  };

  const tileDisabled = (obj) => {
    if (user.calendarProps.length !== 0) {
      let disable = user.calendarProps.findIndex(
        (x) =>
          Number(x.disabled) === obj.date.getDay() ||
          Date.parse(x.disabled) === obj.date.getTime()
      );
      return disable === -1 ? false : true;
    }
  };

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />

      <div
        className={`${classes.main} ${
          isMobile ? "height-100vh width-100" : ""
        }`}
      >
        {!isMobile && <UserSidebar />}
        <div
          className={`${classes.contentContainer} ${
            isMobile ? "width-100" : ""
          }`}
        >
          <div
            className={`${classes.innerContentContainer} ${
              isMobile ? "padding-20 width-100" : ""
            }`}
          >
            <div
              className={`${classes.contentContainerTitle} ${
                isMobile ? "marginB-1" : ""
              } fonts-regular`}
            >
              {process.env.REACT_APP_INSTANCE === "cadastre"
                ? "Записване на час"
                : "Заявка за среща"}
            </div>

            <TextField
              select
              onChange={(e) => {
                let nameValue = user.departments.filter(
                  (d) => d.id === Number(e.target.value)
                );
                setDepartment(e.target.value);
                let name = nameValue[0].display_name;
                setDepartmentName(name);
              }}
              fullWidth
              value={department}
              variant="outlined"
              InputProps={{
                style: { borderRadius: "20px" },
              }}
            >
              {user.departments &&
                user.departments.map((department, i) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.display_name}
                  </MenuItem>
                ))}
            </TextField>
            <div style={{ marginTop: "10px" }} />
            {user.loading && (
              <CircularProgress
                size={25}
                thickness={2}
                style={{ position: "absolute" }}
              />
            )}
            {isMobile && (
              <RenderCalendar
                departmentId={department}
                value={dateObj}
                tileDisabled={tileDisabled}
                onChange={onDateChange}
                comment={user.graphics.length !== 0 && user.graphics[0].note}
              />
            )}
            {isMobile && <div style={{ marginTop: "10px" }} />}
            <CustomTable
              graphics={user.graphics}
              onBook={(graphic, userId) =>
                setGraphicData({ graphic, userId, departmentName })
              }
            />
          </div>
          {!isMobile && (
            <RenderCalendar
              departmentId={department}
              value={dateObj}
              tileDisabled={tileDisabled}
              onChange={onDateChange}
              comment={user.graphics.length !== 0 && user.graphics[0].note}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={user.isOpenModal}
        width={isMobile ? "80%" : "40%"}
        type="createAppointment"
        payload={graphicData}
        reload={() => getGraphics({ id: Number(department), date })}
      />
      <Modal
        isOpen={user.isOpenSuccess}
        width={isMobile ? "80%" : "40%"}
        type="appoitmentApproved"
        title={`${
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на час"
            : "Заявка за среща"
        }`}
        textContent={`${
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Успешно записахте час за приемно време."
            : "Вашата заявка за среща е подадена успешно. Очаквайте потвърждение."
        }`}
        icon={require("../../../assets/images/check-success.svg")}
      />
    </>
  );
};

UserPortal.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const UserPortalPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <UserPortal {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  getDepartments: () => dispatch(getAllDepartments()),
  getGraphics: (payload) => dispatch(getAllGraphics(payload)),
  getDisabledDays: (payload) => dispatch(getCalendarProps(payload)),
  setToggleLoading: () => dispatch(setLoading()),
  clearUserGraphic: () => dispatch(clearUserGraphic()),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(UserPortalPage);
