export const UserTypes = {
  EDIT_USER_INFO: "EDIT_USER_INFO",
  EDIT_USER_INFO_SUCCESS: "EDIT_USER_INFO_SUCCESS",
  TOGGLE_USER_MODAL: "TOGGLE_USER_MODAL",
  TOGGLE_SUCCESS_MODAL: "TOGGLE_SUCCESS_MODAL",
  TOGGLE_ERROR_MODAL: "TOGGLE_ERROR_MODAL",
  ERROR_PASS_MODAL: "ERROR_PASS_MODAL",
  ERROR_PASSWORDS_DONT_MATCH: "ERROR_PASSWORDS_DONT_MATCH",
  SUCCESS_MODAL_CLOSE: "SUCCESS_MODAL_CLOSE",
  SUCCESS_MODAL_OPEN: "SUCCESS_MODAL_OPEN",
  TOGGLE_VIDEOCALL: "TOGGLE_VIDEOCALL",
  VIDEO_CALL_CLOSE: "VIDEO_CALL_CLOSE",
  VIDEO_CALL_OPEN: "VIDEO_CALL_OPEN",
  TOGGLE_DECLINE: "TOGGLE_DECLINE",
  DECLINE_CLOSE: "DECLINE_CLOSE",
  DECLINE_OPEN: "DECLINE_OPEN",
  GET_ALL_GRAPHICS: "GET_ALL_GRAPHICS",
  GET_ALL_GRAPHICS_SUCCESS: "GET_ALL_GRAPHICS_SUCCESS",
  GET_ALL_DEPARTMENTS: "GET_ALL_DEPARTMENTS",
  GET_ALL_DEPARTMENTS_SUCCESS: "GET_ALL_DEPARTMENTS_SUCCESS",
  MAKE_APPOINTMENT: "MAKE_APPOINTMENT",
  MAKE_APPOINTMENT_SUCCESS: "MAKE_APPOINTMENT_SUCCESS",
  GET_ALL_APPOINTMENTS: "GET_ALL_APPOINTMENTS",
  GET_ALL_APPOINTMENTS_SUCCESS: "GET_ALL_APPOINTMENTS_SUCCESS",
  GET_CALENDAR_PROPS: "GET_CALENDAR_PROPS",
  GET_CALENDAR_PROPS_SUCCESS: "GET_CALENDAR_PROPS_SUCCESS",
  TOGGLE_LOADING: "TOGGLE_LOADING",
  CLEAR_USER_GRAPHIC: "CLEAR_USER_GRAPHIC",
  CLEAR_CANCELED_MEETING: "CLEAR_CANCELED_MEETING",
};

export const editUserInfo = (payload) => ({
  type: UserTypes.EDIT_USER_INFO,
  payload,
});

export const toggleUserModal = () => ({
  type: UserTypes.TOGGLE_USER_MODAL,
});

export const toggleSuccessModal = () => ({
  type: UserTypes.TOGGLE_SUCCESS_MODAL,
});

export const toggleErrorModal = () => ({
  type: UserTypes.TOGGLE_ERROR_MODAL,
});

export const toggleErrorPass = () => ({
  type: UserTypes.ERROR_PASS_MODAL,
});

export const toggleErrorDontMatch = () => ({
  type: UserTypes.ERROR_PASSWORDS_DONT_MATCH,
});

export const successModalClose = () => ({
  type: UserTypes.SUCCESS_MODAL_CLOSE,
});
export const successModalOpen = () => ({
  type: UserTypes.SUCCESS_MODAL_OPEN,
});

export const getAllGraphics = (payload) => ({
  type: UserTypes.GET_ALL_GRAPHICS,
  payload,
});

export const getAllGraphicsSuccess = (payload) => ({
  type: UserTypes.GET_ALL_GRAPHICS_SUCCESS,
  payload,
});

export const toggleVideoCall = (payload) => ({
  type: UserTypes.TOGGLE_VIDEOCALL,
  payload,
});

export const videoCallModalClose = () => ({
  type: UserTypes.VIDEO_CALL_CLOSE,
});
export const videoCallModalOpen = () => ({
  type: UserTypes.VIDEO_CALL_OPEN,
});

export const toggleDecline = (payload) => ({
  type: UserTypes.TOGGLE_DECLINE,
  payload,
});

export const declineModalClose = () => ({
  type: UserTypes.DECLINE_CLOSE,
});

export const declineModalOpen = () => ({
  type: UserTypes.DECLINE_OPEN,
});

export const getAllDepartments = () => ({
  type: UserTypes.GET_ALL_DEPARTMENTS,
});

export const makeAppointment = (payload) => ({
  type: UserTypes.MAKE_APPOINTMENT,
  payload,
});

export const makeAppointmentSuccess = (payload) => ({
  type: UserTypes.MAKE_APPOINTMENT_SUCCESS,
  payload,
});

export const getAllAppointments = (payload) => ({
  type: UserTypes.GET_ALL_APPOINTMENTS,
  payload,
});

export const getCalendarProps = (payload) => ({
  type: UserTypes.GET_CALENDAR_PROPS,
  payload,
});

export const setLoading = () => ({
  type: UserTypes.TOGGLE_LOADING,
});

export const clearUserGraphic = () => ({
  type: UserTypes.CLEAR_USER_GRAPHIC,
});

export const actions = {
  toggleUserModal,
  toggleSuccessModal,
};
