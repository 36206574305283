import authManager from "./authManager";
import { setUserData } from "./helpers";
import { cadastre } from "./constants";

const authProvider = {
  login: ({ username, password }, props) => {
    const request = new Request(`${process.env.REACT_APP_BE_API}/Auth`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
      mode: "cors",
    });
    return fetch(request)
      .then(async (response) => {
        const responseJson = await response.json();
        if (response.status < 200 || response.status >= 300) {
          const responseError = !!responseJson.ErrorCode
            ? `api.errors.${responseJson.ErrorCode}`
            : response.statusText;
          throw new Error(responseError);
        }
        return responseJson;
      })
      .then((response) => {
        const {
          access_token,
          user_id,
          first_name,
          last_name,
          email,
        } = response;
        authManager.setJwt({ access_token, user_id });
        setUserData(first_name, last_name, email);
        let permissions = authManager.getPermissions();
        if (permissions === "admin") {
          process.env.REACT_APP_INSTANCE === cadastre
            ? props.history.push("/admin/create-appointment")
            : props.history.push("/admin/schedules");
        } else {
          props.history.push("/user-portal");
        }
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  register: ({ username, password, name_surname }) => {
    let firstName = name_surname.split(" ")[0];
    let lastName = name_surname.split(" ")[1];
    const request = new Request(
      `${process.env.REACT_APP_BE_API}/Auth/create?email=${username}&password=${password}&firstName=${firstName}&lastName=${lastName}`
    );

    return fetch(request)
      .then(async (response) => {
        const responseJson = await response.json();
        if (response.status < 200 || response.status >= 300) {
          const responseError = !!responseJson.ErrorCode
            ? `api.errors.${responseJson.ErrorCode}`
            : response.statusText;
          throw new Error(responseError);
        }
        return responseJson;
      })
      .then((res) => {
        if (res.result === "Error") return Promise.reject();
        return Promise.resolve();
      });
  },

  logout: () => {
    authManager.removeJwt();
    return Promise.resolve();
  },

  checkAuth: () => {
    const jwt = authManager.getJwt();
    const jwtExpire = authManager.getJwtExpire();
    const jwtRefreshTimeout = authManager.getJwtRefreshTimeout();
    const timeNow = new Date().getTime();
    const timeToExpire = jwtExpire - timeNow;

    if (jwt && jwtExpire && timeToExpire > 0) {
      if (!(authManager.getUserId() && authManager.getPermissions())) {
        if (!authManager.setJwt({ access_token: jwt })) {
          authManager.removeJwt();
          return Promise.reject();
        }
      }

      if (!jwtRefreshTimeout) {
        authManager.refreshJwt(timeToExpire);
      }
      return Promise.resolve();
    }
    authManager.removeJwt();
    return Promise.reject();
    // return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      authManager.removeJwt();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const jwt = authManager.getJwt();
    const role = authManager.getPermissions();
    return jwt && role && Promise.resolve(role);
    // return Promise.resolve()
  },
};

export default authProvider;
