import React from "react";
import NavButton from "../../NavButton/NavButton";
import Header from "../../Header/Header";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import authProvider from "../../../utils/authProvider";
import { isMobile } from "../../../utils/consts";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.headerColor,
    height: "70px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  sidebar: {
    backgroundColor: theme.palette.common.sidebarBgColor,
    height: "100%",
    maxWidth: "20%",
    flex: "0 0 20%",
  },
  sidebarTextContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  sidebarText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "10px",
    color: theme.palette.common.sidebarTitlesColor,
    marginTop: "20px",
    textTransform: "uppercase",
  },
  sidebarTitle: {
    textAlign: "center",
    fontSize: "16px",
    color: theme.palette.common.sidebarTitlesColor,
    textTransform: "uppercase",
  },
  contentContainer: {
    minHeight: "100%",
    maxWidth: "80%",
    flex: "0 0 80%",
    display: "flex",
  },
  sideButtonsContainer: {
    marginTop: "30px",
    minHeight: "calc(100vh - 70px)",
  },
  bntContainer: {
    padding: "5px 3rem",
  },
  pressed: {
    color: theme.palette.common.sidebarMenuItemPressed,
  },
}));

const UserSidebar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = window.location.pathname;

  let sidebarTitle = "",
    sidebarSubtitle = "",
    sidebarLogo = "";

  switch (process.env.REACT_APP_INSTANCE) {
    case "cadastre":
      sidebarTitle = "Агенция по";
      sidebarSubtitle = "Геодезия, картография и кадастър";
      sidebarLogo = require("../../../assets/images/cadastre-logo.PNG");
      break;
    case "serdika":
      sidebarTitle = "Столична община";
      sidebarSubtitle = 'Район "Сердика"';
      sidebarLogo = require("../../../assets/images/gerb-sofia.png");
      break;
    case "pernik":
      sidebarTitle = "Община";
      sidebarSubtitle = "Перник";
      sidebarLogo = require("../../../assets/images/pernik-logo.jpg");
      break;
    case "mladost":
      sidebarTitle = "Столична община";
      sidebarSubtitle = 'Район "Младост"';
      sidebarLogo = require("../../../assets/images/mladost-logo.png");
      break;
  }

  return (
    <>
      <div
        className={`${classes.sidebar} ${
          location === "/nav" ? "sidebar-mobile" : ""
        }`}
      >
        <div
          className={`${classes.sidebarTextContainer} ${
            isMobile ? "text-container-mobile" : ""
          }`}
        >
          <img alt="logo" src={sidebarLogo} />
          <div
            className={`${
              process.env.REACT_APP_INSTANCE === "cadastre"
                ? classes.sidebarTitle
                : classes.sidebarText
            } fonts-regular`}
          >
            {sidebarTitle}
            <div className={`${classes.sidebarTitle} fonts-regular`}>
              {sidebarSubtitle}
            </div>
          </div>
        </div>
        <div
          className={`${classes.sideButtonsContainer} ${
            location === "/nav" ? "align-items-center" : ""
          }`}
        >
          <NavButton
            text={`${
              process.env.REACT_APP_INSTANCE === "cadastre"
                ? "Записване на час"
                : "Заявка за среща"
            }`}
            styles={classes.bntContainer}
            linkStyle={
              location === "/user-portal" || location === "/"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            onClick={() => {
              history.push("/user-portal");
            }}
          />
          <NavButton
            text="История на моите срещи"
            styles={classes.bntContainer}
            linkStyle={
              location === "/my-appointments-history"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            onClick={() => {
              history.push("/my-appointments-history");
            }}
          />
          <NavButton
            text="Моят профил"
            styles={classes.bntContainer}
            linkStyle={
              location === "/my-profile"
                ? `${classes.linkStyle} ${classes.pressed} `
                : classes.linkStyle
            }
            onClick={() => {
              history.push("/my-profile");
            }}
          />
          {isMobile && (
            <NavButton
              text="Изход"
              styles={classes.bntContainer}
              linkStyle={
                location === "/my-profile"
                  ? `${classes.linkStyle} ${classes.pressed} `
                  : classes.linkStyle
              }
              onClick={() => {
                authProvider.logout();
                history.push("/login");
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UserSidebar;
