import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { getAllGraphicsSuccess, toggleErrorModal } from "../../../actions/user";
import { getAdminGraphicsSuccess } from "../../../actions/schedule";
import { makeGetUser } from "../../../selectors/selectors";

import Modal from "../../Modal/Modal";

import { Notification } from "react-admin";
import { useTranslate } from "ra-core";

import { theme } from "../../../utils/theme";
import authProvider from "../../../utils/authProvider";

const useStyles = makeStyles((theme) => ({
  appbar: {
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.common.headerColor,
    color: theme.palette.primary.contrastText,
  },
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#f4f7fa",
    background: "url(/bg.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "7em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.common.headerColor,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primaryJsColor2,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  regContainer: {
    padding: "20px",
  },
  regLink: {
    textDecoration: "none",
  },
  regBtn: {
    color: "#007bff",
    marginLeft: "10px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  loginBtn: {
    backgroundColor: theme.palette.common.commonBtnBgColor,
    "&:hover": {
      backgroundColor: theme.palette.common.commonBtnHoverBgColor,
    },
  },
  forggPass: {
    padding: "0px 20px 20px 20px",
  },
  forggBtn: {
    color: "#007bff",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();

  const handleSubmit = (auth) => {
    setLoading(true);
    authProvider
      .login(auth, props)
      .then(() => {
        props.clearUserGraphics([]);
        props.clearAdminGraphics([]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error:: ", err);
        props.errorModal();
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar variant="dense">
          <Avatar className={classes.icon}>
            <EventAvailableIcon />
          </Avatar>
          <Typography
            variant="body1"
            component="h1"
            align="center"
            style={{ width: "100%" }}
          >
            {process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Записване на часове за подаване на заявления в СГКК София – град"
              : "Портал за заявяване на срещи с администрацията"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <Avatar className={classes.icon}>
                    <LockIcon />
                  </Avatar>
                </div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      // autoFocus
                      name="username"
                      component={renderInput}
                      label="Електронна поща"
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    className={classes.loginBtn}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && (
                      <CircularProgress
                        size={25}
                        thickness={2}
                        style={{ position: "absolute" }}
                      />
                    )}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
                <div className={classes.regContainer}>
                  Нямате акаунт?
                  <Link
                    className={classes.regLink}
                    to={{ pathname: "/register" }}
                  >
                    <span className={classes.regBtn}>Към регистрация</span>
                  </Link>
                </div>
                <div className={classes.forggPass}>
                  <Link
                    className={classes.regLink}
                    to={{ pathname: "/forgotten-pass" }}
                  >
                    <span className={classes.forggBtn}>Забравена парола</span>
                  </Link>
                </div>
              </Card>
              <Notification />
            </div>
          </form>
        )}
      />
      <Modal
        isOpen={props.user.isOpenError}
        width="40%"
        type="error"
        title="Грешка"
        textContent="Грешен имейл или парола."
        icon={require("../../../assets/images/warning.svg")}
      />
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const LoginPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <Login {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  clearUserGraphics: (payload) => dispatch(getAllGraphicsSuccess(payload)),
  clearAdminGraphics: (payload) => dispatch(getAdminGraphicsSuccess(payload)),
  errorModal: () => dispatch(toggleErrorModal()),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(LoginPage);
