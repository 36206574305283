import { UserTypes } from "../actions/user";

const initialState = {
  isOpenModal: false,
  isOpenSuccess: false,
  isOpenError: false,
  isOpenErrorPass: false,
  isOpenErrorPassDontMatch: false,
  isOpenVideoCall: false,
  isOpenDecline: false,
  graphics: [],
  departments: [],
  appointments: null,
  calendarProps: [],
  loading: false,
};

const makeAppointment = (payload, state) => {
  return state.graphics.map((graphic, i) => {
    if (Number(graphic.id) === Number(payload)) {
      return { ...graphic, reserved: true };
    }
    return graphic;
  });
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case UserTypes.EDIT_USER_INFO:
      return { ...state, user: payload };

    case UserTypes.GET_ALL_DEPARTMENTS_SUCCESS:
      return { ...state, departments: payload };

    case UserTypes.TOGGLE_USER_MODAL:
      return {
        ...state,
        isOpenModal: !state.isOpenModal,
      };

    case UserTypes.TOGGLE_SUCCESS_MODAL:
      return {
        ...state,
        isOpenSuccess: !state.isOpenSuccess,
      };

    case UserTypes.TOGGLE_ERROR_MODAL:
      return {
        ...state,
        isOpenError: !state.isOpenError,
      };

    case UserTypes.ERROR_PASS_MODAL:
      return { ...state, isOpenErrorPass: !state.isOpenErrorPass };

    case UserTypes.ERROR_PASSWORDS_DONT_MATCH:
      return {
        ...state,
        isOpenErrorPassDontMatch: !state.isOpenErrorPassDontMatch,
      };

    case UserTypes.SUCCESS_MODAL_CLOSE:
      return { ...state, isOpenSuccess: false };

    case UserTypes.SUCCESS_MODAL_OPEN:
      return { ...state, isOpenSuccess: true };

    case UserTypes.TOGGLE_VIDEOCALL:
      return {
        ...state,
        isOpenVideoCall: !state.isOpenVideoCall,
      };

    case UserTypes.VIDEO_CALL_CLOSE:
      return { ...state, isOpenVideoCall: false };

    case UserTypes.VIDEO_CALL_OPEN:
      return { ...state, isOpenVideoCall: true };

    case UserTypes.TOGGLE_DECLINE:
      return {
        ...state,
        isOpenDecline: !state.isOpenDecline,
      };

    case UserTypes.DECLINE_CLOSE:
      return { ...state, isOpenDecline: false };

    case UserTypes.DECLINE_OPEN:
      return { ...state, isOpenDecline: true };

    case UserTypes.GET_ALL_GRAPHICS_SUCCESS:
      return {
        ...state,
        loading: false,
        graphics: payload,
      };

    case UserTypes.MAKE_APPOINTMENT_SUCCESS:
      return { ...state, graphics: makeAppointment(payload, state) };

    case UserTypes.GET_ALL_APPOINTMENTS_SUCCESS:
      return { ...state, appointments: payload };

    case UserTypes.GET_CALENDAR_PROPS_SUCCESS:
      return { ...state, calendarProps: payload };

    case UserTypes.CLEAR_CANCELED_MEETING:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          future_meetings: state?.appointments?.future_meetings?.filter(
            (meeting) => meeting.id !== payload
          ),
        },
      };
    case UserTypes.CLEAR_USER_GRAPHIC:
      return {
        ...state,
        graphics: [],
      };

    case UserTypes.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    default:
      return state;
  }
};

export default user;
