import React from "react";
import { connect } from "react-redux";
import { toggleUserModal } from "../../actions/user";
import { makeGetUser } from "../../selectors/selectors";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import authManager from "../../utils/authManager";
import "./styles.scss";
import moment from "moment";

const CustomTable = (props) => {
  const renderField = (type) => {
    return (
      <span className={type ? "unavailable" : "available"}>
        {type ? "зает час" : "свободен час"}
      </span>
    );
  };

  let userId = authManager.getUserId();
  return (
    <table className="main-table">
      <thead>
        <tr>
          <th className="flex1">Час</th>
          <th className="flex3">Статус</th>
          <th className="flex1">Действие</th>
        </tr>
      </thead>
      <tbody>
        {props.graphics &&
          props.graphics.length !== 0 &&
          props.graphics.map((graphic, i) => {
            if (!graphic.enabled) {
              return null;
            }
            if (!graphic.reserved) {
              return (
                <tr
                  style={
                    i === props.graphics.length - 1
                      ? { borderBottom: "none" }
                      : {}
                  }
                  key={i}
                >
                  <td className="hour flex1">{`${moment(
                    graphic.start_hour
                  ).format("HH:mm")} - ${moment(graphic.end_hour).format(
                    "HH:mm"
                  )}`}</td>
                  <td className="flex3">{renderField(graphic.reserved)}</td>
                  <td className="flex1">
                    <span
                      className="book-btn"
                      onClick={() => {
                        props.onBook(graphic, userId);
                        props.toggleModal();
                      }}
                    >
                      {process.env.REACT_APP_INSTANCE === "cadastre"
                        ? "Запиши час "
                        : "Заяви среща "}
                      <KeyboardArrowRightIcon />
                    </span>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr
                  style={
                    i === props.graphics.length - 1
                      ? { borderBottom: "none" }
                      : {}
                  }
                  key={i}
                >
                  <td className="hour flex1">{`${moment(
                    graphic.start_hour
                  ).format("HH:mm")} - ${moment(graphic.end_hour).format(
                    "HH:mm"
                  )}`}</td>
                  <td className="flex3">{renderField(graphic.reserved)}</td>
                  <td className="flex1"></td>
                </tr>
              );
            }
          })}
      </tbody>
    </table>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(CustomTable);
