import React from 'react'
import { connect } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import RaisedButton from '../../RaisedButton/RaisedButton'
import { makeGetUser } from '../../../selectors/selectors'

import { toggleErrorPass, toggleErrorDontMatch } from '../../../actions/user'
// import './styles.scss'

const PassErrorModal = (props) => {
  return (
    <div className='success-modal-main'>
      <div className='raised-btn-head'>
        <CloseIcon
          className='close'
          onClick={() => {
            if (props.props.type === 'errorPassDontMatch') {
              props.toggleErrorDontMatch()
              return
            }
            if (props.props.type === 'errorPass') {
              props.errorClose()
              return
            }
          }}
        />
      </div>
      {props.props.icon && <img alt='success' className='success-check' src={props.props.icon} />}
      <span className='text margintop20'>
        {props.props.title && props.props.title}
      </span>
      <div className='raised-btn-body'>

        <div className='alert-text'>
          {props.props.textContent && props.props.textContent}
        </div>
      </div>

      <div className='raised-btn-footer'>
        <RaisedButton
          className={props.props.type === 'appoitmentDeclined' ? 'sucess-btn-ok' : 'sucess-btn'}
          text='OK'
          onClick={() => {
            if (props.props.type === 'errorPassDontMatch') {
              props.toggleErrorDontMatch()
              return
            }
            if (props.props.type === 'errorPass') {
              props.errorClose()
              return
            }
          }}
        />
      </div>
    </div>
  )
}

const makeMapStateToProps = () => {
  const getUser = makeGetUser()
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props)
    }
  }
  return mapStateToProps
}

const mapDispatchToProps = (dispatch) => ({
  errorClose: () => dispatch(toggleErrorPass()),
  toggleErrorDontMatch: () => dispatch(toggleErrorDontMatch())
})

export default connect(makeMapStateToProps, mapDispatchToProps)(PassErrorModal)