import React, { Component } from 'react'
import './styles.scss'
class RaisedButton extends Component {
  render() {
    const { text, clear, raised, onClick, className, disabled } = this.props
    const containerStyles = []
    if (clear) {
      containerStyles.push(`raised-button-clear ${className}`)
    } else if (raised) {
      containerStyles.push(`raised-button-container raised ${className}`)
    } else {
      containerStyles.push(`raised-button-container ${disabled && 'disabled'} ${className}`)
    }

    return (
      <div
        className={containerStyles}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
      >
        {text || 'text'}
      </div>
    )
  }
}

export default RaisedButton
