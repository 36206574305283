import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import RaisedButton from "../../RaisedButton/RaisedButton";
import { cancelMeeting, toggleCancelModal } from "../../../actions/schedule";

import "./styles.scss";

const SuccessModal = (props) => {
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const meetingId = useSelector((state) => state.schedule.meetingToCancel);
  return (
    <div className="success-modal-main">
      <div className="raised-btn-head">
        <CloseIcon
          className="close"
          onClick={() => dispatch(toggleCancelModal())}
        />
      </div>
      <span className="text margintop20">
        {props.props.title && props.props.title}
      </span>
      <div className="raised-btn-body">
        <div className="alert-text">
          {props.props.textContent && props.props.textContent}
        </div>
      </div>
      <div className="topic">Причина за отказ</div>
      <textarea
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          margin: "20px",
          padding: "50px",
        }}
        className="input-area"
        onChange={(e) => setReason(e.target.value)}
      />
      <div className="raised-btn-footer">
        <div className="row">
          <RaisedButton
            className="sucess-btn-no"
            text="Откажи"
            onClick={() => {
              dispatch(
                cancelMeeting({
                  id: parseInt(meetingId),
                  approved: false,
                  cancelation_reason: reason,
                })
              );
              dispatch(toggleCancelModal());
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
