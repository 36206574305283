import React from "react";
import { connect, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import {
  toggleUserModal,
  toggleSuccessModal,
  toggleVideoCall,
} from "../../actions/user";
import {
  approveMeeting,
  cancelMeeting,
  toggleCancelModal,
  startMeeting,
  endMeeting,
} from "../../actions/schedule";
import { makeGetUser, makeGetSchedule } from "../../selectors/selectors";
import moment from "moment";
import "./styles.scss";

const ToBeAppTable = (props) => {
  const dispatch = useDispatch();
  return (
    <table className="tobe-main-table">
      <thead>
        <tr>
          <th className="flex1">Дата</th>
          <th className="flex1">Час</th>
          <th className="flex1">Номер</th>
          <th className="flex3">Тема</th>
          {process.env.REACT_APP_INSTANCE === "mladost" && (
            <th className="flex3">Номер на документ/ РМЛ</th>
          )}
          <th className="flex3">Име на клиент</th>
          <th className="flex2">Дирекция или отдел</th>
          {!["cadastre", "pernik"].includes(process.env.REACT_APP_INSTANCE) && (
            <th className="flex1">Онлайн</th>
          )}

          <th className="flex1"></th>
          {!props.upcomming && <th className="flex1"></th>}
          {props.showStartStop && <th className="flex1"></th>}
        </tr>
      </thead>
      <tbody>
        {!props.upcomming
          ? props.schedule.unapprovedMeetings &&
            props.schedule.unapprovedMeetings.map((meeting, i) => {
              return (
                <tr key={i}>
                  <td className="hour flex1">
                    {moment(meeting.start_hour).format("DD.MM.YYYY")}
                  </td>
                  <td className="hour flex1">
                    {moment(meeting.start_hour).format("HH:mm")}
                  </td>
                  <td className="hour flex1">{meeting.id}</td>
                  <td className="hour flex3">{meeting?.topic}</td>
                  {process.env.REACT_APP_INSTANCE === "mladost" && (
                    <td className="hour flex3">
                      {meeting?.document_number_rml}
                    </td>
                  )}
                  <td className="hour flex3">{meeting?.applicant}</td>

                  <td className="hour flex2">{meeting.department_name}</td>
                  {!["cadastre", "pernik"].includes(
                    process.env.REACT_APP_INSTANCE
                  ) && (
                    <td className="hour flex1">
                      {meeting.meeting_url && (
                        <a href={meeting.meeting_url}>
                          <img
                            className="online"
                            alt="online"
                            src={require("../../assets/images/camera.svg")}
                          />
                        </a>
                      )}
                    </td>
                  )}
                  <td className="hour color-oldPrimaryColor1 flex1">
                    <Button
                      style={{ marginTop: "2px" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        if (props.upcomming) {
                          props.toggleSuccess();
                          props.cancelMeeting(meeting.id);
                        } else {
                          props.approveMeeting(meeting.id);
                          props.toggleSuccess();
                        }
                      }}
                    >
                      {props.upcomming ? "Откажи" : "Одобри"}
                    </Button>
                  </td>
                  <td className="hour color-oldPrimaryColor1 flex1">
                    <Button
                      style={{ marginTop: "2px" }}
                      fullWidth
                      variant="contained"
                      onClick={() => dispatch(toggleCancelModal(meeting.id))}
                    >
                      Откажи
                    </Button>
                  </td>
                </tr>
              );
            })
          : props.schedule.approvedMeetings &&
            props.schedule.approvedMeetings.map((meeting, i) => {
              return (
                <tr key={i}>
                  <td className="hour flex1">
                    {moment(meeting.start_hour).format("DD.MM.YYYY")}
                  </td>
                  <td className="hour flex1">
                    {moment(meeting.start_hour).format("HH:mm")}
                  </td>
                  <td className="hour flex1">{meeting.id}</td>
                  <td className="hour flex3">{meeting?.topic}</td>
                  {process.env.REACT_APP_INSTANCE === "mladost" && (
                    <td className="hour flex3">
                      {meeting?.document_number_rml}
                    </td>
                  )}
                  <td className="hour flex3">{meeting?.applicant}</td>
                  <td className="hour flex2">{meeting.department_name}</td>
                  {!["cadastre", "pernik"].includes(
                    process.env.REACT_APP_INSTANCE
                  ) && (
                    <td className="hour flex1">
                      {meeting.meeting_url && (
                        <a href={meeting.meeting_url}>
                          <img
                            className="online"
                            alt="online"
                            src={require("../../assets/images/camera.svg")}
                          />
                        </a>
                      )}
                    </td>
                  )}

                  {props.showStartStop ? (
                    <>
                      {!meeting.started &&
                        !props.schedule.approvedMeetings.find(
                          (x) => x.started && !x.passed
                        ) &&
                        !meeting.passed && (
                          <td className="hour color-oldPrimaryColor1 flex1">
                            <Button
                              style={{ marginTop: "2px" }}
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                props.startMeeting(meeting.id);
                              }}
                            >
                              Стартирай
                            </Button>
                          </td>
                        )}

                      {meeting.started && !meeting.passed && (
                        <td className="hour color-oldPrimaryColor1 flex1">
                          <Button
                            style={{ marginTop: "2px" }}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              props.endMeeting(meeting.id);
                            }}
                          >
                            Приключи
                          </Button>
                        </td>
                      )}

                      {!meeting.started && !meeting.passed && (
                        <td className="hour color-oldPrimaryColor1 flex1">
                          <Button
                            style={{ marginTop: "2px" }}
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              if (props.upcomming) {
                                dispatch(toggleCancelModal(meeting.id));
                              } else {
                                props.approveMeeting(meeting.id);
                                props.toggleSuccess();
                              }
                            }}
                          >
                            {props.upcomming ? "Откажи" : "Одобри"}
                          </Button>
                        </td>
                      )}
                    </>
                  ) : (
                    <>
                      <td className="hour flex1">
                        <Button
                          style={{ marginTop: "2px" }}
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            if (props.upcomming) {
                              dispatch(toggleCancelModal(meeting.id));
                            } else {
                              props.approveMeeting(meeting.id);
                              props.toggleSuccess();
                            }
                          }}
                        >
                          {props.upcomming ? "Откажи" : "Одобри"}
                        </Button>
                      </td>
                      {!props.upcomming && <td className="hour flex1"></td>}
                    </>
                  )}
                </tr>
              );
            })}
      </tbody>
    </table>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  toggleVideoCall: () => dispatch(toggleVideoCall()),
  approveMeeting: (payload) => dispatch(approveMeeting(payload)),
  cancelMeeting: (payload) => dispatch(cancelMeeting(payload)),
  startMeeting: (payload) => dispatch(startMeeting(payload)),
  endMeeting: (payload) => dispatch(endMeeting(payload)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(ToBeAppTable);
