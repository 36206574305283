import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import DateTime from "react-datetime";
import "./styles.scss";
import moment from "moment";
import "moment/locale/bg";

const DateTimePicker = ({
  startDate,
  authorized,
  setStartDate,
  updateSession,
  style,
  ...props
}) => {
  const [time] = useState(moment(new Date()).format("HH:mm"));

  const minDate = moment().add(5, "minute");
  const maxDate = authorized
    ? moment().add(168, "hour")
    : moment().add(24, "hour");

  const checkDateTime = (sDateTime) => {
    let mDateTime = moment(sDateTime);

    // if (mDateTime.isBefore(minDate)) mDateTime = minDate;
    // else if (mDateTime.isAfter(maxDate)) mDateTime = maxDate;

    setStartDate(mDateTime.format("YYYY-MM-DD HH:mm"));
  };

  // useEffect(() => {
  //   if (startDate) {
  //     checkDateTime(startDate);
  //   }
  // }, [startDate]);

  // const handleDateChange = (dateTime) => {
  //   let mDateTime = moment(dateTime);
  //   let mStartDate = moment(startDate);

  //   if (!mDateTime.isValid()) mDateTime = moment();
  //   if (!mStartDate.isValid()) mStartDate = moment();

  //   checkDateTime(
  //     `${mDateTime.format('YYYY-MM-DD')} ${mStartDate.format('HH:mm')}`
  //   );
  //   // updateSession();
  // };

  const handleTimeChange = (dateTime) => {
    let mDateTime = moment(dateTime);
    let mStartDate = moment(startDate);

    if (!mDateTime.isValid()) mDateTime = moment();
    if (!mStartDate.isValid()) mStartDate = moment();

    checkDateTime(
      `${mStartDate.format("YYYY-MM-DD")} ${mDateTime.format("HH:mm")}`
    );
    // updateSession();
  };

  // const getValidDates = (current) => {
  //   return current.isBetween(minDate, maxDate, 'days', '[]');
  // };

  const timeConstraints = useMemo(() => {
    return {
      hours: {
        min: 0,
        max: 23,
        step: 1,
      },
      minutes: {
        min: 0,
        max: 59,
        step: 1,
      },
    };
  }, [startDate, minDate, maxDate]);

  const inputProps = {
    readOnly: true,
    className: props.className,
    placeholder: props.placeholder || "Начален час",
  };

  return (
    <>
      <DateTime
        key={time}
        value={props.value}
        locale={"bg"}
        dateFormat={false}
        inputProps={style ? { ...inputProps, style } : inputProps}
        utc={false}
        onChange={handleTimeChange}
        timeConstraints={timeConstraints}
        className={props.styles ? `${props.styles} timePicker` : "timePicker"}
      />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimePicker);
