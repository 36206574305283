import React, { useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import CheckboxButton from "../../CheckboxButton/CheckboxButton";
import RaisedButton from "../../RaisedButton/RaisedButton";
import { makeGetUser } from "../../../selectors/selectors";
import Input from "../../Input/Input";
import {
  toggleUserModal,
  toggleSuccessModal,
  makeAppointment,
  makeAppointmentSuccess,
} from "../../../actions/user";
import moment, { RFC_2822 } from "moment";
import "./styles.scss";
import { api } from "../../../api";
import { useHistory } from "react-router";

const CreateAppointment = ({ ...props }) => {
  const history = useHistory();
  let { graphic, userId, departmentName, department } = props.props.payload;
  const [topic, setTopic] = useState("");
  const [documentNumberRml, setDocumentNumberRml] = useState("");
  const [isOnline, setOnline] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(undefined);

  let onlineMeetingCheckbox = (
    <CheckboxButton
      checked={isOnline}
      labelClassName="container-check-field"
      className="container-check-field"
      label={`Заявявам желание за провеждане на онлайн ${
        process.env.REACT_APP_INSTANCE === "cadastre" ? "час" : "среща"
      }`}
      onChange={() => setOnline(!isOnline)}
    />
  );

  switch (process.env.REACT_APP_INSTANCE) {
    case "cadastre":
      onlineMeetingCheckbox = null;
      break;
    case "pernik":
      onlineMeetingCheckbox = null;
      break;
    default:
    // throw Error("should never happen")
  }
  return !error ? (
    <div className="appointment-modal-main">
      <div className="close-container">
        <CloseIcon className="close" onClick={() => props.toggleModal()} />
      </div>
      <div className="raised-btn-head">
        <span className="text">
          {process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на час"
            : "Заявка за среща"}
        </span>
        {process.env.REACT_APP_INSTANCE !== "cadastre" && (
          <span className="subtitle">
            {process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Успешно записахте час за приемно време."
              : "Вашата заявка за среща ще бъде подадена за одобрение."}
          </span>
        )}
      </div>
      <div className="raised-btn-body">
        <div className="name-container">
          <div className="names">
            <div className="name-field text-transform-up">Дата:</div>
            <div className="name-field text-transform-up">Час:</div>
            <div className="name-field text-transform-up">
              {process.env.REACT_APP_INSTANCE === "cadastre"
                ? "С:"
                : "Среща с:"}
            </div>
          </div>
          <div className="names10">
            <div className="name-field">
              {moment(graphic.start_hour).format("DD.MM.YYYY")}
            </div>
            <div className="name-field">
              {moment(graphic.start_hour).format("HH:mm")}
            </div>
            <div className="name-field">
              {(department &&
                props.user.departments &&
                props.user.departments.length > 0 &&
                props.user.departments.find((x) => x.id === department) &&
                props.user.departments.find((x) => x.id === department)
                  .display_name) ||
                departmentName}
            </div>
          </div>
        </div>
      </div>
      {// props.user.admin
      window.location.pathname !== "/user-portal" && (
        <Input
          // value={name}
          placeholder="Име на заявителя"
          icon={require("../../../assets/images/user.svg")}
          mainStyle="big-input"
          className="big-input-inner"
          onChange={(e) => setName(e.target.value)}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (name === "") {
                return;
              }
              setName(e.target.value);
            }
          }}
        />
      )}
      {process.env.REACT_APP_INSTANCE === "mladost" && (
        <>
          <div className="topic">Номер на документ/ РМЛ</div>
          <textarea
            className="input-area"
            onChange={(e) => setDocumentNumberRml(e.target.value)}
          />
        </>
      )}
      <div className="topic">
        {process.env.REACT_APP_INSTANCE === "cadastre"
          ? "Тема "
          : "Тема на срещата "}
        (до 500 символа)
      </div>
      <textarea
        className="input-area"
        onChange={(e) => setTopic(e.target.value)}
      />
      {onlineMeetingCheckbox}
      <div className="raised-btn-footer">
        <RaisedButton
          className="save-btn"
          text={
            process.env.REACT_APP_INSTANCE === "cadastre"
              ? "Запиши час"
              : "Заяви среща"
          }
          onClick={() => {
            api
              .makeAppointment({
                graphic_id: graphic.id,
                user_id: Number(userId),
                applicant: name,
                is_webex: isOnline,
                topic: topic,
                document_number_rml: documentNumberRml,
              })
              .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                  props.toggleModal();
                  props.toggleSuccess();
                  props.makeNewAppointmentSuccess(graphic.id);
                } else if (response.status === 409) {
                  response.json().then((data) => setError(data));
                }
              });
          }}
        />
      </div>
    </div>
  ) : (
    <div className="appointment-modal-main">
      <div className="close-container">
        <CloseIcon className="close" onClick={() => props.toggleModal()} />
      </div>
      <div className="raised-btn-head">
        <span style={{ textAlign: "center" }} className="text">
          {[2, 3, 4].includes(error.code) && (
            <>Неуспешно запазване на час за среща. Моля, опитайте отново.</>
          )}
          {error.code === 1 && (
            <>Този час е вече запазен, моля изберете друг.</>
          )}
          {error.code === 5 && (
            <>
              За този ден вече имате запазен час. Ако желаете да го промените е
              необходимо да откажете часът и да запазите нов от
            </>
          )}
          {error.code === 6 && (
            <>Часът който се опитвате да запазите вече е отминал</>
          )}
        </span>
      </div>
      {error.code === 5 && (
        <div
          className="delete-row"
          style={{ cursor: "pointer", color: "#08b9ff" }}
          onClick={() => history.push("/my-appointments-history")}
        >
          <span
            style={{ fontSize: "25px", color: "#08b9ff" }}
            className="checkbox-label"
          >
            тук
          </span>
        </div>
      )}
      <div className="raised-btn-footer">
        <RaisedButton
          className="save-btn"
          text="Затвори"
          onClick={() => {
            props.toggleModal();
            if (error.code === 1) {
              props.props.reload();
            }
            setError(undefined);
          }}
        />
      </div>
    </div>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  makeNewAppointment: (payload) => dispatch(makeAppointment(payload)),
  makeNewAppointmentSuccess: (payload) =>
    dispatch(makeAppointmentSuccess(payload)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CreateAppointment);
