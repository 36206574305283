import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../../utils/theme";

import Header from "../../Header/Header";
import UserSidebar from "../UserSidebar/UserSidebar";
import NavButton from "../../NavButton/NavButton";
import Modal from "../../Modal/Modal";
import Input from "../../Input/Input";

import { toggleSuccessModal, editUserInfo } from "../../../actions/user";
import { makeGetUser } from "../../../selectors/selectors";

import authManager from "../../../utils/authManager";
import { setUserData } from "../../../utils/helpers";
import { useStyles } from "../../../utils/styles";
import { isMobile } from "../../../utils/consts";
import "./styles.scss";

const MyProfilePage = ({ ...props }) => {
  const classes = useStyles();
  const [firstName, setFNChange] = useState(false);
  const [lastName, setLNChange] = useState(false);
  const [email, setEmailChange] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [newPasswordConfirmVisible, setNewPasswordConfirmVisible] = useState(
    false
  );
  const [firstNameValue, setFNValue] = useState(
    localStorage.getItem("firstName")
  );
  const [lastNameValue, setLNValue] = useState(
    localStorage.getItem("lastName")
  );
  const [emailValue, setEmailValue] = useState(localStorage.getItem("email"));

  const userId = authManager.getUserId();

  const cutText = (text) => {
    return text.length > 25 ? `${text.substr(0, 25)}...` : text;
  };

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />
      <div className={classes.main}>
        {!isMobile && <UserSidebar />}
        <div
          className={`${classes.contentContainer} ${
            isMobile ? "max-width-100 flex-1" : ""
          }`}
        >
          <div
            className={`${classes.innerContentContainer} ${
              isMobile ? "padding-20" : ""
            }`}
          >
            <div className={`${classes.contentContainerTitle} fonts-regular`}>
              Моят профил
            </div>
            <div className={`${classes.dateText} fonts-regular`}>
              Основни данни
            </div>
            <div className={classes.nameContainer}>
              <div className={classes.names}>
                <div className="name-field text-transform-up">Име</div>
                <div className="name-field text-transform-up">Фамилия</div>
                <div className="name-field text-transform-up">EMAIL</div>
              </div>
              <div className={classes.names10}>
                {firstName ? (
                  <div className="row-edit">
                    <Input
                      value={firstNameValue}
                      placeholder="Име"
                      className="name-input"
                      onChange={(e) => setFNValue(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          if (firstNameValue === "") {
                            return;
                          }
                          setFNChange(false);
                        }
                        setFNValue(e.target.value);
                      }}
                    />
                    <span
                      className="edit-btn save"
                      onClick={() => setFNChange(false)}
                    >
                      запази
                    </span>
                  </div>
                ) : (
                  <div className="name-field">
                    {cutText(firstNameValue)}{" "}
                    <span
                      className="edit-btn"
                      onClick={() => setFNChange(true)}
                    >
                      смени
                    </span>
                  </div>
                )}
                {lastName ? (
                  <div className="row-edit">
                    <Input
                      value={lastNameValue}
                      placeholder="Фамилия"
                      className="name-input"
                      onChange={(e) => setLNValue(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          if (lastNameValue === "") {
                            return;
                          }
                          setLNChange(false);
                        }
                        setLNValue(e.target.value);
                      }}
                    />
                    <span
                      className="edit-btn save"
                      onClick={() => setLNChange(false)}
                    >
                      запази
                    </span>
                  </div>
                ) : (
                  <div className="name-field">
                    {cutText(lastNameValue)}{" "}
                    <span
                      className="edit-btn"
                      onClick={() => setLNChange(true)}
                    >
                      смени
                    </span>
                  </div>
                )}
                {email ? (
                  <div className="row-edit">
                    <Input
                      value={emailValue}
                      placeholder="EMAIL"
                      className="name-input"
                      onChange={(e) => setEmailValue(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          if (emailValue === "") {
                            return;
                          }
                          setEmailChange(false);
                        }
                        setEmailValue(e.target.value);
                      }}
                    />
                    <span
                      className="edit-btn save"
                      onClick={() => setEmailChange(false)}
                    >
                      запази
                    </span>
                  </div>
                ) : (
                  <div className="name-field">
                    {cutText(emailValue)}{" "}
                    <span
                      className="edit-btn"
                      onClick={() => setEmailChange(true)}
                    >
                      смени
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className={`subtitle2`}>Смени парола</div>
            <div
              className={`${classes.inputContainer} ${
                isMobile ? "max-width-100" : ""
              }`}
            >
              <Input
                password="password"
                type={passwordVisible ? "text" : "password"}
                className={classes.input}
                placeholder="Настояща парола"
                onChange={(e) => setPassword(e.target.value)}
                onPassClick={() => setPasswordVisible(!passwordVisible)}
              />
              <Input
                password="password"
                type={newPasswordVisible ? "text" : "password"}
                className={classes.input}
                placeholder="Нова парола"
                onChange={(e) => setNewPassword(e.target.value)}
                onPassClick={() => setNewPasswordVisible(!newPasswordVisible)}
              />
              <Input
                password="password"
                type={newPasswordConfirmVisible ? "text" : "password"}
                className={classes.input}
                placeholder="Потвърдете новата парола"
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                onPassClick={() =>
                  setNewPasswordConfirmVisible(!newPasswordConfirmVisible)
                }
              />
            </div>
            <NavButton
              styles="save-btn"
              linkStyle="save"
              text="Запази"
              onClick={() => {
                let user = {
                  user_id: userId,
                  email: emailValue,
                  first_name: firstNameValue,
                  last_name: lastNameValue,
                  old_password: password,
                  new_password: newPassword,
                  new_password_confirmation: newPasswordConfirm,
                };
                setUserData(firstNameValue, lastNameValue, emailValue);
                props.editUser(user);
                props.toggleSuccess();
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={props.user.isOpenSuccess}
        width="40%"
        type="appoitmentApproved"
        title="Промяна на данни"
        textContent="Данните ви са променени успешно"
        icon={require("../../../assets/images/check-success.svg")}
      />
    </>
  );
};

MyProfilePage.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const MyProfile = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <MyProfile {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  editUser: (payload) => dispatch(editUserInfo(payload)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(MyProfilePage);
