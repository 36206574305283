import { createMuiTheme } from "@material-ui/core/styles";
import "../vars.scss";
import "../_fonts.scss";

function getTheme(theme) {
  const instance = process.env.REACT_APP_INSTANCE;

  let conditionalTheme = null;

  switch (instance) {
    case "serdika":
      conditionalTheme = require("./themes/serdika-theme");
      break;
    case "cadastre":
      conditionalTheme = require("./themes/cadastre-theme");
      break;
    case "pernik":
      conditionalTheme = require("./themes/pernik-theme");
      break;
    case "mladost":
      conditionalTheme = require("./themes/mladost-theme");
      break;
    default:
      conditionalTheme = require("./themes/default-theme");
  }

  conditionalTheme = conditionalTheme.default;
  conditionalTheme.palette.type = theme.paletteType;

  return createMuiTheme(conditionalTheme);
}

export const theme = getTheme({
  paletteType: "light",
});

export default theme;
