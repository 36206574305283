import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMuiTheme } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../../../utils/theme";

import Header from "../../Header/Header";
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import RaisedButton from "../../RaisedButton/RaisedButton";
import Input from "../../Input/Input";
import CustomizedSelects from "../../Select/Select";
import { isSingleDepartment } from "../../../helpers/helpers";

import {
  toggleSuccessModal,
  getAllDepartments,
  getAllGraphics,
} from "../../../actions/user";
import { createSchedule } from "../../../actions/schedule";
import { makeGetUser, makeGetSchedule } from "../../../selectors/selectors";

import DateTimePicker from "../../DateTimePicker/DateTimePicker";
import RenderCalendar from "../../Calendar/Calendar";
import CheckboxButton from "../../CheckboxButton/CheckboxButton";
import Modal from "../../Modal/Modal";
import moment from "moment";
import { useStyles } from "../../../utils/styles";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import "./styles.scss";

const CreateAppointment = ({ ...props }) => {
  const { width, height } = useWindowDimensions();
  const isMobile = width < 1000;
  useEffect(() => {
    props.getDepartments();
  }, []);
  const isOneDepartment = props?.user?.departments?.length === 1;
  useEffect(() => {
    if (isOneDepartment) {
      setDepartment(props.user.departments[0].id);
    }
  }, [isOneDepartment, props.user.departments]);

  const classes = useStyles();
  const [department, setDepartment] = useState(null);
  const [name] = useState("");
  const [online, setOnline] = useState(false);
  const [startTime, setStartTime] = useState(
    moment()
      .set("hours", 10)
      .set("minutes", 0)
  );
  const [endTime, setEndTime] = useState(
    moment()
      .set("hours", 16)
      .set("minutes", 0)
  );
  const [duration, setDuration] = useState();
  const [interval, setIntervalValue] = useState();
  const [day, setDay] = useState(null);
  const [date, onDateSet] = useState(null);
  const [dateObj, onDateObjSet] = useState(null);
  const [comment, setComment] = useState("");

  const onDaySet = (e) => {
    onDateObjSet(new Date());
    if (!day) {
      let days = [];
      days.push(e);
      setDay(days);
    } else if (day) {
      let index = day.indexOf(e);
      if (index >= 0) {
        day.splice(index, 1);
        setDay(day);
      } else {
        let days = day.slice(0);
        days.push(e);
        setDay(days);
      }
    }
  };

  const checkFields = () => {
    if (
      department &&
      startTime &&
      endTime &&
      duration &&
      interval &&
      (day || date)
    ) {
      return true;
    } else return false;
  };

  const checked = (e) => {
    return day && day.indexOf(e) >= 0;
  };

  const onStartTimeSet = (time) => {
    setStartTime(moment(time));
  };

  const onEndTimeSet = function(time) {
    setEndTime(moment(time));
  };

  const onDateChange = (dateObj) => {
    setDay(null);
    let date = moment(dateObj).format("MM/DD/YYYY");
    onDateSet(date);
    onDateObjSet(dateObj);
    if (department) {
      props.getGraphics({ id: department, date });
    }
  };

  const tileDisabled = (obj) => {
    if (props.user.calendarProps.lenght !== 0) {
      let disable = props.user.calendarProps.findIndex(
        (x) =>
          Number(x.disabled) === obj.date.getDay() ||
          Date.parse(x.disabled) === obj.date.getTime()
      );
      return disable === -1 ? false : true;
    }
    return false;
  };

  return (
    <>
      <Header
        style={classes.header}
        titleStyle={classes.headerTitle}
        title={
          process.env.REACT_APP_INSTANCE === "cadastre"
            ? "Записване на часове за подаване на заявления в СГКК София – град"
            : "Портал приемно време"
        }
      />

      <div
        className={!isMobile && classes.main}
        style={{ backgroundColor: "#efefef", minHeight: `${height}px` }}
      >
        {!isMobile && <AdminSidebar />}

        <div className={!isMobile && classes.contentContainer}>
          <div
            className={
              isMobile
                ? classes.mobileContentContainer
                : classes.innerContentContainer
            }
          >
            <div
              className={`${
                isMobile
                  ? classes.mobileContentContainerTitle
                  : classes.contentContainerTitle
              } fonts-regular`}
            >
              Създай график
            </div>
            <TextField
              select
              onChange={(e) => setDepartment(e.target.value)}
              fullWidth
              value={department}
              variant="outlined"
              InputProps={{
                style: { borderRadius: "20px" },
              }}
            >
              {props.user.departments &&
                props.user.departments.map((department, i) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.display_name}
                  </MenuItem>
                ))}
            </TextField>
            {width < 500 && <div style={{ marginTop: "5px" }} />}
            {isMobile && (
              <RenderCalendar
                departmentId={department}
                value={dateObj}
                tileDisabled={tileDisabled}
                onChange={onDateChange}
              />
            )}
            <CheckboxButton
              styles="margin-top20"
              label="Само с видеоконферента връзка"
              checked={online}
              onChange={() => setOnline(!online)}
            />
            <div className="admin-subtitle">Генериране на часове</div>
            <div className={width < 500 || "admin-row"}>
              <DateTimePicker
                value={moment(startTime)}
                setStartDate={(time) => onStartTimeSet(time)}
                style={
                  width < 500 ? { width: "100%", marginBottom: "2px" } : {}
                }
              />
              <DateTimePicker
                placeholder="Краен час"
                value={moment(endTime)}
                setStartDate={(time) => onEndTimeSet(time)}
                styles={width < 500 || "right-picker"}
                className={width < 500 || "margin-left15"}
                style={
                  width < 500 ? { width: "100%", marginBottom: "2px" } : {}
                }
              />
              <Input
                type="number"
                step={10}
                value={duration}
                placeholder="Продължителност в минути"
                className={
                  width < 500
                    ? "margin-bottom2 font-size11"
                    : "margin-left15 font-size11"
                }
                onChange={(e) => setDuration(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    if (name === "") {
                      return;
                    }
                    setDuration(e.target.value);
                  }
                }}
              />
              <Input
                type="number"
                step={5}
                className={
                  width < 500
                    ? "margin-bottom2 font-size11"
                    : "margin-left15 font-size11"
                }
                value={interval}
                placeholder="Интервал в минути"
                onChange={(e) => setIntervalValue(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    if (name === "") {
                      return;
                    }
                    setIntervalValue(e.target.value);
                  }
                }}
              />
            </div>
            <div
              className={
                width < 500
                  ? "admin-column"
                  : "admin-row margin-top30 padding-left10"
              }
            >
              <CheckboxButton
                checked={checked(1)}
                label="Понеделник"
                className="borderDarkGrey"
                labelClassName="checkbox-label"
                onChange={(e) => onDaySet(1)}
              />
              <CheckboxButton
                checked={checked(2)}
                className={width < 500 ? "borderDarkGrey" : "container-check"}
                label="Вторник"
                labelClassName="checkbox-label"
                onChange={(e) => onDaySet(2)}
              />
              <CheckboxButton
                checked={checked(3)}
                className={width < 500 ? "borderDarkGrey" : "container-check"}
                label="Сряда"
                labelClassName="checkbox-label"
                onChange={(e) => onDaySet(3)}
              />
              <CheckboxButton
                checked={checked(4)}
                className={width < 500 ? "borderDarkGrey" : "container-check"}
                label="Четвъртък"
                labelClassName="checkbox-label"
                onChange={(e) => onDaySet(4)}
              />
              <CheckboxButton
                checked={checked(5)}
                className={width < 500 ? "borderDarkGrey" : "container-check"}
                label="Петък"
                labelClassName="checkbox-label"
                onChange={(e) => onDaySet(5)}
              />
            </div>
            <div className="admin-row">
              <img
                alt="paper"
                className="paper-icon"
                src={require("../../../assets/images/paper.svg")}
              />
              <textarea
                placeholder="Публичен коментар"
                className="text-input-area margin-top30"
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="admin-row flex-end">
              <RaisedButton
                className={
                  width < 500
                    ? "save-btn create-graph-bnt fullwidth"
                    : "save-btn create-graph-btn"
                }
                text="Създай график"
                onClick={() => {
                  let scheduleData;
                  if (!day) {
                    scheduleData = {
                      departmentId: Number(department),
                      for_date: date,
                      online,
                      duration: Number(duration),
                      interval: Number(interval),
                      start_hour: moment(startTime).format("HH:mm"),
                      end_hour: moment(endTime).format("HH:mm"),
                      note: comment,
                    };
                  } else {
                    scheduleData = {
                      departmentId: Number(department),
                      for_weekday: day,
                      online,
                      duration: Number(duration),
                      interval: Number(interval),
                      start_hour: moment(startTime).format("HH:mm"),
                      end_hour: moment(endTime).format("HH:mm"),
                      note: comment,
                    };
                  }

                  if (checkFields()) {
                    // props.toggleSuccess()
                    // window.location.reload(false)
                    props.createNewSchedule(scheduleData);
                  } else alert("Въведете всички полета!");
                }}
              />
            </div>
          </div>
          {!isMobile && (
            <RenderCalendar
              departmentId={department}
              value={dateObj}
              tileDisabled={tileDisabled}
              onChange={onDateChange}
            />
          )}
        </div>
        <Modal
          isOpen={props.user.isOpenSuccess}
          width={isMobile ? "100%" : "40%"}
          type="createAppointmentSuccess"
          title="Графикът е създаден успешно."
          icon={require("../../../assets/images/check-success.svg")}
          onSuccessClick={() => {
            props.history.push("/admin/schedules");
          }}
        />
      </div>
    </>
  );
};

CreateAppointment.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const CreateAppointmentPage = (props) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <CreateAppointment {...props} />
  </ThemeProvider>
);

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const getSchedule = makeGetSchedule();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
      schedule: getSchedule(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  getDepartments: () => dispatch(getAllDepartments()),
  getGraphics: (payload) => dispatch(getAllGraphics(payload)),
  createNewSchedule: (payload) => dispatch(createSchedule(payload)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CreateAppointmentPage);
