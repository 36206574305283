import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "./styles.scss";
import { api } from "../../api";
import authManager from "../../utils/authManager";

const disableWeekdays = (obj) => {
  return obj.date.getDay() === 0 || obj.date.getDay() === 6;
};

function RenderCalendar({ ...props }) {
  const isAdmin =
    authManager.getJwt() && authManager.getPermissions() === "admin";

  const { value, departmentId } = props;
  const [daysWithSchedules, setDaysWithSchedules] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(undefined);

  useEffect(() => {
    if (departmentId) {
      api
        .getDaysWithSchedules({
          month:
            currentMonth ||
            (value ? value.getMonth() + 1 : new Date().getMonth() + 1),
          departmentId: departmentId,
        })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              setDaysWithSchedules(data.map((x) => moment(x)));
            });
          }
        });
    }
  }, [currentMonth, value, departmentId]);

  return (
    <div
      className={
        props.className
          ? `calendar-outter ${props.className}`
          : "calendar-outter"
      }
    >
      <Calendar
        className="calendar-main"
        locale="bg"
        minDetail="month"
        minDate={new Date()}
        maxDate={
          isAdmin
            ? moment(new Date())
                .add(12, "month")
                .toDate()
            : moment(new Date())
                .add(1, "month")
                .toDate()
        }
        prev2Label={null}
        next2Label={null}
        showNeighboringMonth={true}
        defaultValue={new Date()}
        value={props.value}
        tileDisabled={(obj) => {
          return disableWeekdays(obj) || props.tileDisabled(obj);
        }}
        onChange={props.onChange}
        // {...props}
        onActiveStartDateChange={({ activeStartDate }) =>
          setCurrentMonth(activeStartDate.getMonth() + 1)
        }
        tileClassName={({ date }) =>
          isAdmin && daysWithSchedules.find((x) => moment(date).isSame(x))
            ? "day-with-schedule"
            : null
        }
      />
      {props.comment && (
        <div className="calendar-message-container">
          <div className="message-title">Коментар</div>
          <div className="message-text">
            {props.comment || "Срещите се провеждат в стая №1309."}
          </div>
        </div>
      )}
    </div>
  );
}

export default RenderCalendar;
