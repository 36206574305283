import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import {
  toggleUserModal,
  toggleSuccessModal,
  toggleVideoCall,
} from "../../actions/user";
import { makeGetUser } from "../../selectors/selectors";
import "./styles.scss";

const UserMobileTable = (props) => {
  return (
    <>
      {props.user.appointments && !props.passed
        ? props.user.appointments.future_meetings.map((appoint, i) => {
            const length = props.user.appointments.future_meetings.length;
            return (
              <>
                <table
                  style={{ marginBottom: "3px" }}
                  className={`mobile-history-table ${
                    props.passed ? "margin-bottom20" : ""
                  }`}
                >
                  <tbody>
                    <tr key={i}>
                      <th className="data-type">Дата</th>
                      <td className="hour">
                        {moment(appoint.start_hour).format("DD.MM.YYYY")}
                      </td>
                    </tr>
                    <tr key={i}>
                      <th className="data-type">Час</th>
                      <td className="hour">
                        {moment(appoint.start_hour).format("HH:mm")}
                      </td>
                    </tr>
                    <tr key={i}>
                      <th className="data-type">
                        {process.env.REACT_APP_INSTANCE === "cadastre"
                          ? "Часът"
                          : "Срещата"}{" "}
                        ще се проведе с
                      </th>
                      <td className="hour">{appoint.department_name}</td>
                    </tr>
                    {!["cadastre", "pernik"].includes(
                      process.env.REACT_APP_INSTANCE
                    ) && (
                      <tr key={i}>
                        <th className="data-type">Онлайн</th>
                        <td className="hour">
                          {appoint.meeting_url && (
                            <a href={appoint.meeting_url}>
                              <img
                                className="online"
                                alt="online"
                                src={require("../../assets/images/camera.svg")}
                              />
                            </a>
                          )}
                        </td>
                      </tr>
                    )}
                    <Button
                      style={{ marginTop: "2px" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        props.toggleSuccess();
                        if (props.declineMeeting) {
                          props.declineMeeting(appoint.id);
                        }
                      }}
                    >
                      Откажи
                    </Button>
                  </tbody>
                </table>
              </>
            );
          })
        : props.passed &&
          props.user.appointments &&
          props.user.appointments.past_meetings.map((appoint, i) => {
            return (
              <table
                className={`mobile-history-table ${
                  props.passed ? "margin-bottom20" : ""
                }`}
              >
                <tbody>
                  <div key={i}>
                    <tr>
                      <th className="data-type">Дата</th>
                      <td className="hour">
                        {moment(appoint.start_hour).format("DD.MM.YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <th className="data-type">Час</th>
                      <td className="hour">
                        {moment(appoint.start_hour).format("HH:mm")}
                      </td>
                    </tr>
                    <tr>
                      <th className="data-type">
                        {process.env.REACT_APP_INSTANCE === "cadastre"
                          ? "Часът е проведен с"
                          : "Срещата е проведена с"}
                      </th>
                      <td className="hour">{appoint.department_name}</td>
                    </tr>
                    <tr>
                      <th className="data-type">Статус</th>
                      <td className="hour">{appoint.status}</td>
                    </tr>
                  </div>
                </tbody>
              </table>
            );
          })}
    </>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleUserModal()),
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  toggleVideoCall: () => dispatch(toggleVideoCall()),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(UserMobileTable);
