import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import RaisedButton from "../../RaisedButton/RaisedButton";
import { makeGetUser } from "../../../selectors/selectors";
import { cancelMeeting } from "../../../actions/schedule";
import { history } from "../../../utils/store";

import {
  toggleSuccessModal,
  toggleDecline,
  toggleVideoCall,
  successModalClose,
  declineModalClose,
  videoCallModalClose,
  UserTypes,
} from "../../../actions/user";
import "./styles.scss";

const SuccessModal = (props) => {
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  return (
    <div className="success-modal-main">
      <div className="raised-btn-head">
        <CloseIcon
          className="close"
          onClick={() =>
            props.props.closeFail
              ? props.props.closeFail()
              : props.toggleSuccess()
          }
        />
      </div>
      <span className="text margintop20">
        {props.props.title && props.props.title}
      </span>
      <div className="raised-btn-body">
        <div className="alert-text">
          <p style={{ textAlign: "center" }}>
            {props.props.textContent && props.props.textContent}
          </p>
        </div>
      </div>
      {props.props.extraTextContent && (
        <p style={{ textAlign: "center" }}>{props.props.extraTextContent}</p>
      )}
      {props.props.type === "userDecline" && (
        <>
          <div className="topic">Причина за отказ</div>
          <textarea
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              margin: "20px",
              padding: "50px",
            }}
            className="input-area"
            onChange={(e) => setReason(e.target.value)}
          />
        </>
      )}
      <div className="raised-btn-footer">
        {props.props.decline ? (
          <div className="row">
            <RaisedButton
              className="sucess-btn-no"
              text="НЕ"
              onClick={() => {
                props.toggleSuccess();
              }}
            />
            <RaisedButton
              className="sucess-btn-ok margin-left15px"
              text="ДА"
              onClick={() => {
                props.toggleDecline();
                props.toggleSuccess();
                if (props.props.type === "userDecline") {
                  props.cancelMeeting({
                    id: Number(props.props.id),
                    cancelation_reason: reason,
                  });
                  dispatch({
                    type: UserTypes.CLEAR_CANCELED_MEETING,
                    payload: parseInt(props.props.id),
                  });
                }
              }}
            />
          </div>
        ) : (
          <RaisedButton
            className={
              props.props.type === "appoitmentDeclined"
                ? "sucess-btn-ok"
                : "sucess-btn"
            }
            text={props.props.type === "videoCall" ? "Начало" : "OK"}
            onClick={() => {
              if (props.props.closeFail) {
                props.props.closeFail();
                return;
              }
              if (props.props.type === "success") {
                props.successClose();
                return;
              }
              if (props.props.type === "createAppointmentSuccess") {
                history.push("/admin/schedules");
                props.toggleSuccess();
                return;
              }
              props.successClose();
              props.declineClose();
              props.videoCallClose();
              if (props.onSuccessClick) {
                props.onSuccessClick();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

const makeMapStateToProps = () => {
  const getUser = makeGetUser();
  const mapStateToProps = (state, props) => {
    return {
      user: getUser(state, props),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  successClose: () => dispatch(successModalClose()),
  toggleSuccess: () => dispatch(toggleSuccessModal()),
  toggleDecline: () => dispatch(toggleDecline()),
  declineClose: () => dispatch(declineModalClose()),
  toggleVideoCall: () => dispatch(toggleVideoCall()),
  videoCallClose: () => dispatch(videoCallModalClose()),
  cancelMeeting: (payload) => dispatch(cancelMeeting(payload)),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(SuccessModal);
